import PortfolioItemsTable from 'src/javascripts/components/tables/PortfolioItemsTable';

export default class TeamsPortfoliosAdvocacyIndex {

  constructor() {}

  render() {


    // Portfolio items, if present on page
    if ($('.portfolio-items-table').length > 0) {
      $('.portfolio-items-table').each(function() {
        let tableId = $(this).attr('id');
        let portfolioAdvocacyTable = new PortfolioItemsTable($('#' + tableId));
        $.when( portfolioAdvocacyTable.render(tableId) ).then( status => portfolioAdvocacyTable.setUp(tableId) );
      })
    }

  }

}