export default function() {

  $('.minimize-sidebar').click(function() {
  	let teamUrl = $('body').attr('data-team-url');
  	let params = {};
  	params['option'] = {}
  	params['option']['sidebar_minimized'] = 'true'
    $('.sidebar-app').addClass('minimized');
    $('.sidebar-offset').addClass('minimized');
    $('.expand-sidebar').removeClass('opaque');

    $.ajax({
      type: 'PATCH',
      url: '/' + teamUrl + '/update_option?' + $.param(params),
      dataType: 'application/json',
    });

  });

  $('.expand-sidebar').click(function() {
  	let teamUrl = $('body').attr('data-team-url');
  	let params = {};
  	params['option'] = {}
  	params['option']['sidebar_minimized'] = 'false'

    $('.sidebar-app').removeClass('minimized');
    $('.sidebar-offset').removeClass('minimized');
    $('.expand-sidebar').addClass('opaque');

    $.ajax({
      type: 'PATCH',
      url: '/' + teamUrl + '/update_option?' + $.param(params),
      dataType: 'application/json',
    });

  });


}