import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';
import Swal from 'sweetalert2';

export default class PortfolioModelItemsTable extends Table {

  render() {

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);
    let colLen = cols.length;

    // If data order col is present, use that
    let orderCol = this.el.attr('data-order-col');
    let order;
    if (typeof orderCol !== 'undefined') {
      order = orderCol;
    } else {
      order = colLen - 2;
    }

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    let infoEmptyMessage = $('#' + tableId).attr('data-info-empty');
    if (typeof infoEmptyMessage === 'undefined') {infoEmptyMessage = "No assets yet. Add securities or import with a CSV file.";}
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      paging: true,
      pageLength: 10,
      lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
      lengthChange: true,
      pagingType: 'simple',
      searching: true,
      order: [[order, 'desc']],
      columns: cols,
      columnDefs: [
        {targets: 0, searchable: false, visible: false},
        {targets: 1, searchable: false, visible: false},
      ],
      dom: 'tlrp',
      responsive: { details: false },
      language: { 
        search: "_INPUT_",
        searchPlaceholder: "Search assets...",
        lengthMenu: "Show _MENU_",
        infoEmpty: infoEmptyMessage,
        emptyTable: infoEmptyMessage,
        zeroRecords: infoEmptyMessage
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Add hover button links to rows, if passed in data
        if (typeof data.hover_links !== 'undefined') { AddRowHoverButtons(row, data.hover_links); }

      },
      drawCallback: function( settings ) {

        // On update of an asset weight, update total
        $('.asset-weight').blur(function() {          
          let assetWeight = parseFloat( $(this).val(), 10);
          $('.save-portfolio-model').attr('data-unsaved-changes', true);
          let teamUrl = $('#portfolioAssetsCard').attr('data-team-url');
          let portfolioId = $('#portfolioAssetsCard').attr('data-portfolio-id');
          let modelId = $('#portfolioAssetsCard').attr('data-model-id');
          let itemId = $(this).closest('.input-group').find('input[type=\'hidden\']').val();
          let params = {};
          params['portfolio_model_item'] = {}
          params['portfolio_model_item']['weighting'] = assetWeight;
          if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
          let url;
          url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/items/' + itemId + '?' + $.param(params);
          $.ajax({
            type: 'PATCH',
            url: url,
            dataType: 'application/json',
            beforeSend: function(request) {
              request.setRequestHeader("Content-Type", "application/json");
              request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
              request.setRequestHeader("Session-Token", sessionToken);
              request.setRequestHeader("Email", userEmail);
            },
            complete(result) { 
              let weight = JSON.parse(result.responseText).weight * 100;
              weight = parseFloat( weight, 10).toFixed(1);
              $('#portfolioModelWeight').empty().append(weight + "%");
            }
          });
        });

        let itemsCount = settings.json.recordsTotal;
        $('#portfolioItemsCount').empty().append("(" + itemsCount + ")");

        // Check if already present; if yes then remove spinner and opacity
        let dt = $('#' + tableId).DataTable();
        if (typeof dt !== 'undefined') {
          $('#portfolioAssetsCard').find('.spinner-container').remove();
          $('#portfolio_model_items_body').css('opacity', 1);
          $('.model-interaction').removeClass('disabled');
        }

        // On update of an asset weight, update total
        $('.asset-attribute').change(function() {
          let assetVal = $(this).val();
          let attribute = $(this).attr('data-attribute');
          let oTable = $('#' + tableId).DataTable();

          // Add spinner and opacity
          Spinner($('#portfolioAssetsCard'));
          $('#portfolio_model_items_body').css('opacity', 0.25);
          $('.model-interaction').addClass('disabled');

          // Set unsaved changes to true
          $('.save-portfolio-model').attr('data-unsaved-changes', true);

          // Build ajax call to update weight of model item
          let teamUrl = $('#portfolioAssetsCard').attr('data-team-url');
          let portfolioId = $('#portfolioAssetsCard').attr('data-portfolio-id');
          let modelId = $('#portfolioAssetsCard').attr('data-model-id');
          let itemId = $(this).attr('data-item-id');
          let params = {};
          params['portfolio_model_item'] = {}
          params['portfolio_model_item'][attribute] = assetVal;
          if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}

          // Set URL
          let url;
          url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/items/' + itemId + '?' + $.param(params);

          // Update attribute
          $.ajax({
            type: 'PATCH',
            url: url,
            dataType: 'application/json',
            beforeSend: function(request) {
              request.setRequestHeader("Content-Type", "application/json");
              request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
              request.setRequestHeader("Session-Token", sessionToken);
              request.setRequestHeader("Email", userEmail);
            },
            complete(result) {
              oTable.ajax.reload();
            }
          });
        });

        // Remove a filter on button select, if present    
        $('.remove-model-item').click(function(ev) {

          // Prevent default
          ev.preventDefault();

          // Set vars
          let teamUrl = $('#portfolioAssetsCard').attr('data-team-url');
          let portfolioId = $('#portfolioAssetsCard').attr('data-portfolio-id');
          let modelId = $('#portfolioAssetsCard').attr('data-model-id');
          let isReusable = $('#portfolioAssetsCard').attr('data-is-reusable');
          let updateReusableOk = $('#portfolioAssetsCard').attr('data-update-reusable-ok');

          // Set el
          let el = $(this);

          // Hide tooltip
          $('.tooltip').tooltip('hide');

          // Add spinner and opacity
          Spinner($('#portfolioAssetsCard'));
          $('#portfolio_model_items_body').css('opacity', 0.25);

          // Build ajax call to add/remove selection from model filters
          let params = {};
          params['item_type'] = el.attr('data-item-type');
          if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
          let itemId = el.attr('data-item-id');
          let url = '/' + teamUrl + '/models/' + modelId + '/items/' + itemId + '?' + $.param(params);

          // Create/remove a filter for model
          $.ajax({
            type: 'DELETE',
            url: url,
            dataType: 'script',
            complete(result) { 

              // Reload table data
              $('#portfolio_model_items_table').DataTable().ajax.reload();

              // Re-enable typeaheads and add filters
              $('.model-interaction').removeClass('disabled');

              // Set unsaved changes to true
              $('.save-portfolio-model').attr('data-unsaved-changes', true);

            },
          });
        });

        // Init tooltips
        $('[data-toggle="tooltip"]').tooltip();


      }, 
      initComplete: function() {
        // Remove spinner and set opacity to 1 
        $('#portfolioAssetsCard').find('.spinner-container').remove();
        $('#portfolio_model_items_body').css('opacity', 1);
      }

    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    return 'setup';

  }


}
