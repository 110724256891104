// Add data attributes to created rows on a table
// Add attributes based on passed data, if data available

export default function(row, data) {

  // Core record attributes required for multiple functions
  if (typeof data.name !== 'undefined') { $(row).attr("data-record-name", data.name); }
  if (typeof data.record_type !== 'undefined') { $(row).attr("data-record-type", data.record_type); }
  if (typeof data.record_id !== 'undefined') { $(row).attr("data-record-id", data.record_id); }
  if (typeof data.token !== 'undefined') { $(row).attr("data-record-token", data.token); }
  if (typeof data.profile_type !== 'undefined') { $(row).attr("data-profile-type", data.profile_type); }
  if (typeof data.profile_id !== 'undefined') { $(row).attr("data-profile-id", data.profile_id); }

  // Impact formula attribute used in net impact navigation
  if (typeof data.formula_id !== 'undefined') { $(row).attr("data-formula-id", data.formula_id); }
  if (typeof data.slug !== 'undefined') { $(row).attr("data-slug", data.slug); }

};