import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildChartUrl from 'src/javascripts/components/charts/url/BuildChartUrl';
import SetChartTotal from 'src/javascripts/components/charts/actions/SetChartTotal';
import CreateChartLegend from 'src/javascripts/components/charts/legend/CreateChartLegend';
import ChangeChartDate from 'src/javascripts/components/charts/actions/ChangeChartDate';

export default class {

  constructor(el) { 
    this.el = el; 
    this.chartId = this.el.attr('id');
  }

  getData() {

    // Set chart element id
    const chartId = this.el.attr('id');

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set record to get data for and display in chart
    let displayRecord = this.el.attr('data-display-record')

    // Return null if no display record present
    if (typeof displayRecord === 'undefined') {return null;}

    // Set record to get data for and display in chart
    const record = JSON.parse(displayRecord);

    // Build url
    const url = BuildChartUrl(chartId, record);

    // Set url parameters for CORS requests to main Ethos site
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Get data
    return $.ajax({
      type: "GET",
      dataType: "json",
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      async: false,
      url: url
    });

  }


  render() {}
    // handled in sub-classes

  setUp(chart) {

    // Return if no chart available (don't attempt to set up below)
    if (typeof chart === 'undefined') {return;}

    // Set name and total at top of chart
    SetChartTotal(chart, chart.data()[0].id);

    // Listen for when user updates dates of chart
    ChangeChartDate(chart);

    // Set chart data to allow for accessing chart programmatically (used to allow tables to add/remove data)
    // Also allows for destroying chart before Turbolinks cache
    // https://stackoverflow.com/questions/25765596/how-to-get-object-reference-from-selector-for-c3-js-charts
    let chartId = '#' + $(chart.element).attr('id');
    $(chartId).data('c3-chart', chart);

    // Get selected records to show in legend
    // Causing issues when before other functions above; appears to be returning for net-impact and returns chart
    // and not running functions after it
    CreateChartLegend(chart);

    // Remove spinner
    $(chart.element).find('.spinner-container').remove();

    return chart;
  }

};

