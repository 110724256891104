import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import Swal from 'sweetalert2';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';

export default class TeamMembershipsTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set table id
    const tableId = this.el.attr('id');

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      paging: false,
      dom: 't',
      ordering: true,
      order: [[1, 'asc']],
      searching: true,
      drawCallback: function( settings ) {

        // Hide paging if total display is equal to or less than page length
        CheckPaging(tableId, settings);

        // Remove spinner after table has drawn
        $('#' + tableId).find('.spinner-container').remove();        

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Update a member's role on click
    $('.status-item').click(function(e) {
      e.preventDefault();
      let roleId = $(this).attr('data-role-id');
      let roleName = $(this).attr('data-role-name');
      let email = $(this).attr('data-email');
      let membershipId = $(this).attr('data-membership-id');
      let teamUrl = $('#team_memberships_table').attr('data-team-url');
      let text = "Confirm changing the role for " + email + " to " + roleName;
      let url = '/' + teamUrl + '/memberships/' + membershipId;

      // Validate
      return Swal.fire({
        title: "Are you sure?",
        text: text,
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        },

      }).then((result) => {
        
        if (result.value) {

          // Unlink the account
          return $.ajax({
            type: 'PATCH',
            url: url,
            data: {'team_membership': {'role_id': roleId}},
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success(result) { 
              let url = window.location.href + "?notice=Successfully updated member's role";
              return Turbolinks.visit(url);
            },
            error(error) { 
              let url = window.location.href + "?notice=There was a problem, please contact us for support";
              return Turbolinks.visit(url);
            }
          });

        }
      });

    });


    // Update a member's role on click
    $('.delete-member').click(function(e) {
      e.preventDefault();
      let email = $(this).attr('data-email');
      let membershipId = $(this).attr('data-membership-id');
      let teamName = $('#team_memberships_table').attr('data-team-name');
      let teamUrl = $('#team_memberships_table').attr('data-team-url');
      let text = "Confirm you would like to remove " + email + " from " + teamName;
      let url = '/' + teamUrl + '/memberships/' + membershipId;

      // Validate
      return Swal.fire({
        title: "Are you sure?",
        text: text,
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        
        if (result.value) {

          // Unlink the account
          $.ajax({
            type: 'DELETE',
            url: url,
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success(result) { 
              let url = window.location.href + "?notice=Successfully removed member";
              return Turbolinks.visit(url);
            },
            error(error) { 
              let url = window.location.href + "?notice=There was a problem, please contact us for support";
              return Turbolinks.visit(url);
            }
          });

        }

      });
    });

    $('.reinvite-member').click(function(e) {
      e.preventDefault();
      let email = $(this).attr('data-email');
      let membershipId = $(this).attr('data-membership-id');
      let teamUrl = $('#team_memberships_table').attr('data-team-url');
      let text = "Confirm resending an invitation email to " + email;
      let url = '/' + teamUrl + '/memberships/' + membershipId + '/reinvite';

      // Validate
      return Swal.fire({
        title: "Are you sure?",
        text: text,
        animation: false,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light border',
          popup: 'animated fadeIn faster'
        }
      }).then((result) => {
        
        if (result.value) {

          // Unlink the account
          $.ajax({
            type: 'PATCH',
            url: url,
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            complete(result) { 
              let url = '/' + teamUrl + '/memberships?notice=Invitation email resent to ' + email;
              return Turbolinks.visit(url);
            },
          });

        }

      });
    });


    return 'setup';

  }

}
