import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';

export default class PortfolioCarbonFootprintTable extends Table {

  render(tableId) {

    // Build url, use to get filtered data with server-side processing
    const dataUrl = $('#' + tableId).attr('data-api-url');

    // Set CORS variables
    const sessionToken = $('body').attr('data-session-token');
    const userEmail = $('body').attr('data-email');
    const ethosCorsKey = $('body').attr('data-ethos-cors-key');
    const mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Set additional data vars (used below in table creation)
    const cols = GetCols(this.el);
    let colLen = cols.length;

    // Render table
    // Most table options handled through data attrs on table element
    // https://datatables.net/manual/options
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      dom: 'tp',
      columns: cols,
      paging: true,
      pageLength: 10,
      searching: true,
      ordering: true,
      order: [[colLen - 1, 'desc']],
      language: {
        infoEmpty: "Couldn't find any footprint data. Please contact us with any questions.",
        emptyTable: "Couldn't find any footprint data. Please contact us with any questions.",
        zeroRecords: "Couldn't find any footprint data. Please contact us with any questions."
      },

      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        if (data.record_id !== null) {

          // Add hoverlink buttons to rows
          let link = 'https://' + mainEthosUrl + '/' + data.record_type + '/' + data.record_id;
          let name = 'View profile';
          let linkObjects = [{link: link, name: name, remote: false, target: '_blank'}];
          AddRowHoverButtons(row, linkObjects);

        } else {

          $(row).addClass('no-click');

        }

      },

      initComplete: function( settings ) {

        // initComplete runs once table has been fully drawn, with ajax async
        // https://datatables.net/reference/option/initComplete
        // Remove spinner after table has drawn
        $('#footprintDetailsContainer').find('.spinner-container').remove();
        $('#footprintDetailsTableContainer').removeClass('opaque');

        // Set total
        $('#footprintTotalCol').empty().append(settings.json.total + " metric tons");

      }

    });

    // Return success status
    return 'rendered'

  }

  setUp(tableId) {

    // Set actions for clicking on a row
    let tableRowClickAction = function(row) {

      // Get request url from table data attribute
      let url = row.closest('.table').attr('data-request-url');

      // Replace url record id with data from row
      if (url.includes(":record_id")) {
        let recordId = row.attr('data-record-id');
        url = url.replace(':record_id', recordId);
      }

      // Replace url record type with data from row
      if (url.includes(":record_type")) {
        let recordType = row.attr('data-record-type');
        url = url.replace(':record_type', recordType);
      }

      // Open in new tab
      window.open(url);

    };

    // Set click areas
    let firstTd;
    firstTd = 'tbody > tr > td:first-child';
    $('#' + tableId).on('click', firstTd, function() { return tableRowClickAction($(this).closest('tr')); });

    const otherTds = 'tbody > tr > td:not(:first-child)';
    $('#' + tableId).on('click', otherTds, function() { return tableRowClickAction($(this).closest('tr')); });

    return 'setup';

  }

};