import SignUpForm from 'src/javascripts/components/forms/SignUpForm';
import PasswordVisibility from 'src/javascripts/components/forms/PasswordVisibility';
import CustomValidators from 'src/javascripts/components/forms/CustomValidators';

export default class Landing {

  constructor() {}

  render() {

    // Ease in top image
    if ($('.bg-image-top').hasClass('opaque')) {
      $('.bg-image-top, .bg-image-prev').toggleClass('opaque');
    }

    // Ease in any feature image
    if ( ($('.feature-image-top').length !== 0) && ($('.feature-image-top').hasClass('opaque')) ) {
      $('.feature-image-top').toggleClass('opaque');
    }

    // Rotating statement 
    let rotateLoop;
    let RotateActive = function() {
      return rotateLoop = setTimeout(function() {
        let activeR = $('.rotating.active');
        let nextR = activeR.next('.rotating');
        if (activeR.hasClass('last')) {
          return
        } else {
          activeR.removeClass('active');
          nextR.addClass('active');
          RotateActive();          
        }
      }, 3000);

    }
    RotateActive();

    // Cancel modal view
    if ($('.cancel-modal').length !== 0) {
      $('.cancel-modal').click(function() {
        let path = $(this).attr('data-path');
        Turbolinks.visit(path);
      });
    }

    $(document).on("turbolinks:before-cache", function() { 

      // Hide dropdown before cache, otherwise reappears 
      // for a second when Turbolinks reloads the page
      $('.top-nav-dropdown').removeClass('show');

      // Remove critical path css -- it conflicts with main stylesheets
      $('#criticalPathStylesheet').remove();

      // Tear down slick
      $('.customer-logos').slick('unslick');

      // Tear down rotate function
      clearTimeout(rotateLoop);
      $('.rotating:last-child').removeClass('active');
      $('.rotating:first-child').addClass('active');

    });


    // Sign up form if on sign-up page
    if ($('#signup_form').length > 0) {
      SignUpForm();
      PasswordVisibility();
      CustomValidators();
    }


    $('.modal a').click(function(e) {
      e.preventDefault();
      let href = $(this).attr('href');
      let modal = $(this).closest('.modal');

      // Hide modals      
      modal.modal('hide');

      // Visit link when hidden
      modal.on('hidden.bs.modal', function (e) {
        Turbolinks.visit(href);
      });

    });

    $('.customer-logos').slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      dots: false,
      pauseOnHover: false,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 520,
        settings: {
          slidesToShow: 3
        }
      }]
    });

  }

}