import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';

export default class ClientSummaryUpdatesTable extends Table {

  render() {

    Spinner($(this.el).closest('.card'));
    $(this.el).closest('.card-body').addClass('opaque');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Set table id
    const tableId = this.el.attr('id');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Render table
    // Responsive details: false - don't show hidden details on small devices (datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      retrieve: true,
      paging: true,
      pageLength: 10,
      searching: true,
      order: [[0, 'asc']],
      dom: 'tp',
      columns: cols,
      responsive: { details: false },
      language: { 
        infoEmpty: "No data available. Please contact us with any questions.",
        emptyTable: "No data available. Please contact us with any questions.",
        zeroRecords: "No data available. Please contact us with any questions."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Add hover button links to rows, if passed in data
        if (typeof data.hover_links !== 'undefined') { AddRowHoverButtons(row, data.hover_links); }

      },

      drawCallback: function( settings ) {


        // Re-init tooltips for lock icons
        $('[data-toggle=tooltip]').tooltip();

      },

      initComplete: function( settings ) {

        // initComplete runs once table has been fully drawn, with ajax async
        // https://datatables.net/reference/option/initComplete
        // Remove spinner after table has drawn
        let card = $('#' + tableId).closest('.card');
        $(card).find('.spinner-container').remove();
        card.find('.card-body').removeClass('opaque');

      }

    });

  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Set on click action for table rows
    AddRowClickLinks(tableId);

    return 'setup';

  }

};