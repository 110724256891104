import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class PortfolioCausesTable extends Table {

  render(tableId) {

    // Build url, use to get filtered data with server-side processing
    let dataUrl = $('#' + tableId).attr('data-url');

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      paging: true,
      pageLength: 5,
      searching: true,
      ordering: true,
      order: [[0, 'asc']],
      dom: 'tp',
      columns: cols,
      responsive: { details: false },
      language: { 
        infoEmpty: "No data available. Please contact us with any questions.",
        emptyTable: "No data available. Please contact us with any questions.",
        zeroRecords: "No data available. Please contact us with any questions."
      },
      createdRow(row, data, dataIndex) {},
      initComplete: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.table-container').find('.spinner-container').remove();

      }

    });

    return 'rendered';
  }

  setUp(tableId, chartId) {
    return 'setup';
  }

};
