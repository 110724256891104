import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';
import Swal from 'sweetalert2';

export default class PortfolioModelsTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    if (this.el.closest('.card').find('.spinner-container').length === 0) {
      Spinner(this.el);
    }

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set height if in full-window mode
    let $height;
    let $card = this.el.closest('.card');
    if ($card.hasClass('full-view')) {
      $height = $card.height() - 180;
    } else {
      $height = 520;
    }

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      serverSide: true,
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      scrollY: $height,
      scrollX: true,
      scrollCollapse: true,
      fixedColumns: {leftColumns: 1, rightColumns: 1},
      paging: true,
      pageLength: 50,
      searching: true,
      order: [[1, 'desc']],
      dom: 'tlp',
      columns: cols,
      responsive: { details: false },
      language: { 
        lengthMenu: "Show _MENU_ models",
        infoEmpty: "No models found. Add one or reach out to us for support.",
        emptyTable: "No models found. Add one or reach out to us for support.",
        zeroRecords: "No models found. Add one or reach out to us for support."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Add model id
        if (typeof data.model_id !== 'undefined') { $(row).attr("data-id", data.model_id); }

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).closest('.card').find('.spinner-container').remove();
        $('#' + tableId).closest('.card-body').css('opacity', 1);

        // Confirm and delete an item on click
        $('.delete-model').click(function(e) {

          // Set model and item ids
          let teamUrl = $('#portfolio_models_table').attr('data-team-url');
          let modelId = $(this).closest('tr').attr('data-id');
          let name = $(this).closest('tr').attr('data-record-name');
          let text = "Confirm that you would like to remove " + name + ". Any portfolios using this model will retain their current assets."
          let url = '/' + teamUrl + '/models/' + modelId;

          // Validate
          return Swal.fire({
            title: "Are you sure?",
            text: text,
            animation: false,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-light border',
              popup: 'animated fadeIn faster'
            }
          }).then((result) => {
            
            if (result.value) {

              // Unlink the account
              $.ajax({
                type: 'DELETE',
                url: url,
                beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
                success(result) { 
                  let teamUrl = $('#portfolio_models_table').attr('data-team-url');
                  let url = '/' + teamUrl + '/models?notice=Model removed';
                  return Turbolinks.visit(url);
                },
                error(error) { 
                  let teamUrl = $('#portfolio_models_table').attr('data-team-url');
                  let url = '/' + teamUrl + '/models?notice=There was a problem, please contact us for support';
                  return Turbolinks.visit(url);
                }
              });

            }

          });

        });

      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    // Set table
    let table = $('#' + tableId);

    // Set actions for clicking on a row
    let tableRowClickAction = function(row) {

      // Get request url from table data attribute
      let url = row.closest('.table').attr('data-request-url');

      // Replace url record id with data from row
      if (url.includes(":record_id")) {
        let recordId = row.attr('data-record-id');
        url = url.replace(':record_id', recordId);
      }

      // Replace url record type with data from row
      if (url.includes(":record_type")) {
        let recordType = row.attr('data-record-type');
        url = url.replace(':record_type', recordType);
      }

      let exists = url.indexOf("undefined");
      if (exists < 0) {
        return Turbolinks.visit(url)
      } else {
        return
      }

    };

    // Set click areas
    // Need to avoid triggering action when clicking a checkbox -- used for chart tables to 
    // interact with charts
    let firstTd; 
    let secondTd;
    firstTd = 'tbody > tr > td:first-child';
    secondTd = 'tbody > tr > td:nth-child(2)';
    $(table).on('click', firstTd, function() { return tableRowClickAction($(this).closest('tr')); });
    $(table).on('click', secondTd, function() { return tableRowClickAction($(this).closest('tr')); });
    return 'setup';

  }


}
