import Swal from 'sweetalert2';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class NotificationsIndex {

  constructor() {}

  render() {

    // On click of a row
    $('.notification-row td:not(:last-child)').on('click', function() {
      let link = $(this).closest('tr').attr('data-request-url') + '?back_path=/notifications';
      Turbolinks.visit(link);
    });

    // On click of destroy all
    $('.destroy-all-notifications').click(function() {

	    // Set CORS variables
	    let sessionToken = $('body').attr('data-session-token');
	    let userEmail = $('body').attr('data-email');
	    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
	    let mainEthosUrl = $('body').attr('data-main-ethos-url');

		  return Swal.fire({
		    title: "Are you sure?",
		    text: "Are you sure you want to remove all of your notifications? This can't be undone.",
		    animation: false,
		    focusConfirm: false,
		    showCancelButton: true,
		    confirmButtonText: 'Yes',
		    cancelButtonText: 'Cancel',
		    customClass: {
		      confirmButton: 'btn btn-primary',
		      cancelButton: 'btn btn-primary-light',
		      popup: 'animated fadeIn faster'
		    },

		    preConfirm: () => {

		      // Remove the notifications
		      return $.ajax({
		        type: 'DELETE',
		        url: '/notifications/destroy_all',
		        beforeSend: function(request) {
		          request.setRequestHeader("Content-Type", "application/json");
		          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
		          request.setRequestHeader("Session-Token", sessionToken);
		          request.setRequestHeader("Email", userEmail);
		        },
		        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		      });
		    },
		    
		    allowOutsideClick: () => !Swal.isLoading()

		  })
    })

  }

}