import TeamMembershipsTable from 'src/javascripts/components/tables/TeamMembershipsTable';

export default class SettingsTeamsMembershipsIndex {

  constructor() {}

  render() {

    // Render table
    if ( $('#team_memberships_table').length !== 0 ) {
      let table = new TeamMembershipsTable($('#team_memberships_table'));
      $.when( table.render() ).then( status => table.setUp('team_memberships_table') )
    }

  }

}