import CompaniesTypeahead from 'src/javascripts/components/typeaheads/CompaniesTypeahead';
import FundsTypeahead from 'src/javascripts/components/typeaheads/FundsTypeahead';
import CausesTypeahead from 'src/javascripts/components/typeaheads/CausesTypeahead';

export default class LandingMy {

  constructor() {}

  render() {

    // Causes typeahead
    let causesTypeahead = new CausesTypeahead('#causes_search');
    $.when(causesTypeahead.initializeBloodhound('causes'))
     .then( bloodhound => causesTypeahead.render(bloodhound, 45, 'none', 0))
     .then( causesTypeahead.setUp('#causes_search'));

    // Companies typeahead
	  let companiesTypeahead = new CompaniesTypeahead('#companies_search');
    companiesTypeahead.render();

    // Funds typeahead
    let fundsTypeahead = new FundsTypeahead('#funds_search');
    fundsTypeahead.render();

    // On click of a tab, focus on that typeahead
    $('#searchLanding .nav-link').click(function() {
      let tab = $(this).attr("href");
      setTimeout(function() {$(tab + ' .typeahead').focus()}, 300);
    })

  }

}