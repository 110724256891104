import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Typeahead from 'src/javascripts/components/Typeahead';

export default class CausesTypeahead extends Typeahead {

  setUp() {    

    // Go to record when clicked
    return $('.causes-typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) { 

      // Set url
      let url;
      let elUrl = $('.causes-typeahead').attr('data-request-url');
      let slug = suggestion.slug;

      // Set CORS variables
      let sessionToken = $('body').attr('data-session-token');
      let userEmail = $('body').attr('data-email');
      let ethosCorsKey = $('body').attr('data-ethos-cors-key');
      let mainEthosUrl = $('body').attr('data-main-ethos-url');

      if ((typeof slug !== 'undefined') && (slug !== null)) {

        if (typeof elUrl !== 'undefined') {
          url = elUrl;
          url = url.replace(':slug', suggestion.slug);
        } else {
          url = 'https://' + mainEthosUrl + '/causes/' + suggestion.slug;
        }

        // Visit url if value present
        return Turbolinks.visit(url);

      }

    });

  }

}