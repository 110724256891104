// Build url for Api calls to get data for a table
export default function(tableId) {

  // Set element from tableId
  const el = $('#' + tableId);

  // Set params based on filter attributes
  let params = {};
  const fromDate = el.attr('data-api-from-date');
  const scopeId = el.attr('data-api-scope-id');
  const scopeType = el.attr('data-api-scope-type');
  const userId = el.attr('data-user-id');
  const teamId = el.attr('data-team-id');
  const clientId = el.attr('data-client-id');
  const portfolioType = el.attr('data-portfolio-type');
  const reportType = el.attr('data-report-type');
  const recordCount = el.attr('data-api-record-count');
  const companyIds = el.attr('data-api-company-ids');
  const fundIds = el.attr('data-api-fund-ids');
  const slug = el.attr('data-slug');
  const paymentStatus = el.attr('data-payment-status');
  const teamType = el.attr('data-team-type');

  // Set formula id
  let formulaId = $('#formula_filter').attr('data-api-formula-id');
  if (typeof formulaId === 'undefined') {formulaId = el.attr('data-api-formula-id');}

  // Add to params if exist as data attrs on table
  if (typeof fromDate !== 'undefined') { params['from_date'] = fromDate; }
  if (typeof userId !== 'undefined') { params['user_id'] = userId; }
  if (typeof teamId !== 'undefined') { params['team_id'] = teamId; }
  if (typeof clientId !== 'undefined') { params['client_id'] = clientId; }
  if (typeof reportType !== 'undefined') { params['report_type'] = reportType; }
  if (typeof portfolioType !== 'undefined') { params['portfolio_type'] = portfolioType; }
  if (typeof scopeId !== 'undefined') { params['scope_id'] = scopeId; }
  if (typeof scopeType !== 'undefined') { params['scope_type'] = scopeType; }
  if (typeof recordCount !== 'undefined') { params['length'] = recordCount; }
  if (typeof formulaId !== 'undefined') { params['formula_id'] = formulaId; }
  if (typeof companyIds !== 'undefined') { params['company_ids'] = companyIds; }
  if (typeof fundIds !== 'undefined') { params['fund_ids'] = fundIds; }
  if (typeof paymentStatus !== 'undefined') { params['payment_status'] = paymentStatus; }
  if (typeof teamType !== 'undefined') { params['team_type'] = teamType; }

  // Set url based on chart data attr
  // Include Api prefix
  let url = el.attr('data-api-url');

  // Return url with params
  return url + '?' + $.param(params);

};
