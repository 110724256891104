import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import ModelIntroTour from 'src/javascripts/components/tours/ModelIntroTour';
import {companiesBloodhound, filterTypeahead, screensTypeaheadOptimizer, screensLiteTypeaheadOptimizer, screenSetsTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate, brandLogoDisplayTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import PortfolioModelItemsTable from 'src/javascripts/components/tables/PortfolioModelItemsTable';
import Swal from 'sweetalert2';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import 'ion-rangeslider/js/ion.rangeSlider';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default function() {

  // Set constants
  const teamUrl = $('#portfolioAssetsCard').attr('data-team-url');
  const modelId = $('#portfolioAssetsCard').attr('data-model-id');
  const masterModelId = $('#portfolioAssetsCard').attr('data-master-model-id');
  const isReusable = $('#portfolioAssetsCard').attr('data-is-reusable');
  const updateReusableOk = $('#portfolioAssetsCard').attr('data-update-reusable-ok');

  // Set CORS variables
  const sessionToken = $('body').attr('data-session-token');
  const userEmail = $('body').attr('data-email');
  const ethosCorsKey = $('body').attr('data-ethos-cors-key');
  const mainEthosUrl = $('body').attr('data-main-ethos-url');

  // Set function for confirming leaving without saved changes
  const confirmLeave = function(e, href) {
    e.preventDefault();

    // Confirm and then continue with request
    return Swal.fire({
      title: "You have unsaved changes",
      text: "Are you sure you want to leave? You have unsaved changes that have not been applied.",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Continue anyway',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        Turbolinks.visit(href);
      }
    });

  }

  // Set function to reload page with params
  const reloadOptimizer = function(notice = "") {

    // Set params and reload
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + masterModelId + '/edit';
    let params = {};
    params['uc'] = 'true';
    params['assets_scope'] = 'optimizer';
    if (notice.length !== 0) {params['notice'] = notice;}

    let assetsView = $('#portfolio_model_items_table').attr('data-assets-view');
    let fullView = $('#portfolio_model_items_table').attr('data-full-view');
    if (typeof assetsView !== 'undefined') {params['assets_view'] = assetsView;}
    if (typeof fullView !== 'undefined') {params['full_view_model'] = fullView;}

	  // Set closed/collapsed cards, if present
	  let toClose = [];
	  if ($('#filterIndicesCard').hasClass('minimized')) {toClose.push('#filterIndicesCard')}
    if ($('#includeExcludeCard').hasClass('minimized')) {toClose.push('#includeExcludeCard')}
	  if ($('#maxTrackingErrorCard').hasClass('minimized')) {toClose.push('#maxTrackingErrorCard')}
	  if ($('#stocksLimitCard').hasClass('minimized')) {toClose.push('#stocksLimitCard')}
    if ($('#taxLossCard').hasClass('minimized')) {toClose.push('#taxLossCard')}
	  if ($('#minRatingCard').hasClass('minimized')) {toClose.push('#minRatingCard')}
	  if ($('#screenCompaniesCard').hasClass('minimized')) {toClose.push('#screenCompaniesCard')}
	  if ($('#minMaxWeightCard').hasClass('minimized')) {toClose.push('#minMaxWeightCard')}
    params["to_close"] = JSON.stringify(toClose);

	  // Reload with params
    return Turbolinks.visit(url + '?' + $.param(params));

  }


  // Set function to create a filter
  const createFilter = function(el, suggestion, notice = "") {

    // Add to assets card if for cause, client or metric
    let c = el.closest('.card'); 
    Spinner(c);
    $(c).find('.card-body').css('opacity', 0.25);

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['portfolio_model_filter'] = {}
    params['portfolio_model_filter']['model_id'] = modelId;
    params['portfolio_model_filter']['filter_table'] = el.attr('data-filter-table');
    params['portfolio_model_filter']['filter_property'] = el.attr('data-filter-property');
    params['portfolio_model_filter']['filter_calculation'] = el.attr('data-filter-calculation');
    params['portfolio_model_filter']['filter_value'] = suggestion;
    params['update_model'] = 'false' // Add update model false to avoid updating model items with this call
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/filters?' + $.param(params);

    // Create a filter for model
    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'application/json',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 

        // Re-enable typeaheads and add filters
        $('.model-interaction').removeClass('disabled');

        // Set unsaved changes to true
        $('.save-portfolio-model').attr('data-unsaved-changes', true);

        // Check response for a redir message, e.g., if need to direct 
        // user to import (upload) assets
        let response = JSON.parse(result.responseText);
        if ((typeof response.redir !== 'undefined') && (response.redir == 'import')) {

          // Go to import assets
          let url = 'https://' + mainEthosUrl + "/" + teamUrl + "/models/" + modelId + "/imports/new";
          let params = {};
          let display = $('.import-assets').attr('data-display');
          let back_path = $('.import-assets').attr("data-back-path");
          if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
          if (typeof display !== 'undefined') {params['display'] = display;}
          if (typeof back_path !== 'undefined') {params['back_path'] = back_path;}
          params['assets_scope'] = 'optimizer';
          let href = url + '?' + $.param(params);
          Turbolinks.visit(href);

        } else {

          // Reload page with optimizer settings
          reloadOptimizer(notice);

        }

      },
    });

  }

  // Render assets table if present on page
  if ( $('#portfolio_model_items_table_wrapper').length === 0 ) {
    const modelItemsTable = new PortfolioModelItemsTable($('#portfolio_model_items_table'));
    $.when( modelItemsTable.render() ).then( status => modelItemsTable.setUp('portfolio_model_items_table') )
  }

  // Auto show the model name portfolio
  if ( $('#setModelInfo').attr('data-auto-show') === 'true' ) {
    $('#updateModelInfoModal').modal('show');
  }

  // Model intro tour
  ModelIntroTour();

  // Set poll for checking on status of optimizer
  let optimizerPoll = () => {

    // Get generating message on page
    let isGenerating = $('.generating-message');

    // Only continue if status indicator present (if should poll for recs is true)
    if (isGenerating.length !== 0) {

      // Set url to get data
      let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/check_optimizer_status.js';

      // Execute ajax request (using js erb template to render content so can control profile styling more easily)
      // Must specify '.js' otherwise processes as JSON
      $.ajax({
        type: "POST",
        dataType: "script",
        timeout: 2500,
        url: url,
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        },
        complete() {
          setTimeout(function() { optimizerPoll() }, 3000);
        }
      });
    }
  };

  // Reload with different view
  $('#assetsView').change(function() {
    let c = $(this).closest('.card'); 
    Spinner(c);
    $(c).find('.card-body').css('opacity', 0.25);
    let viewType = $(this).val().toLowerCase();
    $('#portfolio_model_items_table').attr('data-assets-view', viewType);
    reloadOptimizer();
  });

  // Poll server for status of mapped items, if generating message present
  if ($('.generating-message').length !== 0) { optimizerPoll(); }

  // On click of remove filter, e.g., market cap
  $('.remove-filter').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // Add to assets card if for cause, client or metric
    let c = $(this).closest('.card'); 
    Spinner(c);
    $(c).find('.card-body').css('opacity', 0.25);

    // Set filter and value to remove
    let filterId = $(this).attr('data-filter-id');
    let value = $(this).attr('data-value');

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['filter_value'] = value;
    params['update_model'] = 'false' // Add update model false to avoid updating model items with this call
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/filters/' + filterId + '?' + $.param(params);

    // Create a filter for model
    $.ajax({
      type: 'DELETE',
      dataType: 'application/json',
      url: url,
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 

        // Re-enable typeaheads and add filters
        $('.model-interaction').removeClass('disabled');

        // Set unsaved changes to true
        $('.save-portfolio-model').attr('data-unsaved-changes', true);

      	// Reload page
      	reloadOptimizer();

      },
    });

  });

  if ($('.optimizer-toast').length > 0) {
    let toast = $('.optimizer-toast');
    $('body').prepend(toast);
    $('.optimizer-toast').toast({autohide: false});
    $('.optimizer-toast').toast('show');
  }

  // Run optimizer on click
  $('.run-optimizer').click(function() {
    $(this).attr('disabled', true);

    // Change button text to loading
    let spinnerText = '<div class="d-flex justify-content-center w-100 xs-spinner-container">';
    spinnerText += '<div class="spinner-border xs text-white" role="status">';
    spinnerText += '<span class="sr-only">Loading...</span></div></div>';
    $('.run-optimizer').empty().append(spinnerText);

    // Check for filter to optimize existing model and no existing assets
    let optimizeExisting = $('#filterIndicesCard').find('.remove-filter[data-value=\'Start with existing model\']').length;
    let currentItemsCount = $('#portfolioItemsCount').text().replace("(","").replace(")","");
    optimizeExisting = parseInt(optimizeExisting);
    currentItemsCount = parseInt(currentItemsCount);

    if ((optimizeExisting > 0) && (currentItemsCount === 0)) {
      return ToastCustom('Upload existing model', 'You selected to optimize an existing model, but have not yet uploaded a model. Upload a model with the \'Import\' button.', 8000);
    }

    // Check for current live optimizer runs by team; if already at limit, don't start another run
    let optimizerRunsLive = $('#portfolio_model_items_table').attr('data-optimizer-runs-live');
    if (optimizerRunsLive > 2) {
      return ToastCustom('Max concurrent Optimizer runs reached', 'You can have up to 2 Optimizer runs processing simultaneously. Wait for another run to finish before starting a new one, or contact us for support.', 8000);
    }

    // Check for tax preferences and presence of purchase date, price, quantity, value
    let taxLossPref = $('#taxLossCard').find('.remove-filter[data-value=\'Harvest tax losses\']').length;
    let taxGainPref = $('#taxLossCard').find('.remove-filter[data-value=\'Realize tax gains\']').length;
    taxLossPref = parseInt(taxLossPref);
    taxGainPref = parseInt(taxGainPref);
    if ((taxLossPref > 0) || (taxGainPref > 0)) {

      // Check for presence of required data
      let oTable = $('#portfolio_model_items_table').DataTable();
      let data = oTable.data();
      let hasPurchasePrice;
      let hasQuantity;
      let hasWeighting;
      if (typeof data[0] !== 'undefined') {
        hasPurchasePrice = data[0].purchase_price_data;
        hasQuantity = data[0].quantity_data;
        hasWeighting = data[0].weighting_data;
      } else {
        hasPurchasePrice = null;
        hasQuantity = null;
        hasWeighting = null;
      }

      // Check for any missing
      if ((typeof data[0] === 'undefined') || (hasPurchasePrice === null) || (hasQuantity === null) || (hasWeighting === null)) {

        // Return toast message
        let message = "<p>You selected a preference for harvesting tax losses or realizing tax gains. In order to assess loss or gain potential, we need some additional information in your imported CSV file.</p>";
        message += "<p>Missing columns in your file are: </p>";
        message += "<div class=\'font-weight-bold ml-2 mb-3\'>";
        if (hasPurchasePrice === null) {message += "<div class=\'mb-1\'>purchase_price</div>";}
        if (hasQuantity === null) {message += "<div class=\'mb-1\'>quantity</div>";}
        if (hasWeighting === null) {message += "<div class=\'mb-1\'>weighting or value</div>";}
        message += "</div><p>Please add the fields to your CSV file and re-import using the <span class=\'font-weight-bold\'>Import</span> button.</p>";
        message += "<p class=\'mb-0\'>Please contact us at success@ethosESG.com for support.</p>";  
        return ToastCustom('Missing required fields', message, 20000);

      }

    }

    // Add spinner and opacity to table
    Spinner($('#portfolioAssetsCard'));

    // Update empty message for table
    $('#portfolio_model_items_table').find('tbody').css('opacity', 0);

    // Empty tracking error, rating and weight
    $('#portfolioModelTrackingError').empty();

    // Empty message on table
    $('#portfolio_model_items_table tbody').empty();

    // Disable buttons
    $('#portfolioAssetsCard .card-header').find('.btn').addClass('disabled');
    $('#portfolioAssetsCard .card-header').find('.btn').attr('disabled', true);
    $('#modelingFooter').find('.btn').addClass('disabled');
    $('#modelingFooter').find('.btn').attr('disabled', true);

    // Run optimizer and then reload
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/run_optimizer'
    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'application/json',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 

      	// Reload page
      	reloadOptimizer();

      },
    })

  });

  // On save add spinners, opacity
  $('.save-portfolio-model').click(function(e) {

    // Submit form if name present
    let nameField = $('#reusableModelNameInput')
    if (nameField.length > 0) {

      let name = nameField.val();
      if (name.length > 0) {

        // Submit form
        Spinner($('#portfolioAssetsCard'));
        $('#portfolio_model_items_body').css('opacity', 0.25);
        $(this).tooltip('hide');

      } else {

        e.preventDefault();
        ToastCustom('Name required', 'Please enter a name for the model', 3000);

      }

    } else {

      // Not a reusable model, so don't require name
      Spinner($('#portfolioAssetsCard'));
      $('#portfolio_model_items_body').css('opacity', 0.25);
      $(this).tooltip('hide');

    }

  });

  // Confirm discard changes
  $('.discard-changes').click(function(e) {
    e.preventDefault();
    let updateUrl = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + masterModelId + '/discard_changes'
    let reloadUrl = $(this).attr('data-reload-url');

    // Confirm and then continue with request
    return Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to discard the changes to your model?",
      animation: false,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light border',
        popup: 'animated fadeIn faster'
      }
    }).then((result) => {
      if (result.value) {
        $.ajax({
          type: 'PATCH',
          url: updateUrl,
          dataType: 'application/json',
          beforeSend: function(request) {
            request.setRequestHeader("Content-Type", "application/json");
            request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
            request.setRequestHeader("Session-Token", sessionToken);
            request.setRequestHeader("Email", userEmail);
          },
          complete(result) { 
            Turbolinks.visit(reloadUrl);
          },
        });
      }
    });
  });

  // If unsaved changes, confirm before leaving 
  $('a:not(.save-portfolio-model):not(.discard-changes):not(.uc-ok)').click(function(e) {
    let uc = $('.save-portfolio-model').attr('data-unsaved-changes');
    let href = $(this).attr('href');
    let isRunning = $('.generating-message').length > 0
    if ( typeof uc !== 'undefined' && !isRunning && uc !== false && href !== '#' ) {
      confirmLeave(e, href);
    }
  });

  // Update name on input
  $('.update-model-info').click(function() {
    let nameVal = $('#reusableModelNameInput').val();
    let descVal = $('#reusableModelDescInput').val();
    let params = {};
    params['portfolio_model'] = {};
    params['portfolio_model']['name'] = nameVal
    params['portfolio_model']['description'] = descVal
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/update_attrs?' + $.param(params);
    $.ajax({
      type: 'PATCH',
      url: url,
      dataType: 'application/json',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 
        // Close modal
        $('#updateModelInfoModal').modal('hide');
        // Reload
        reloadOptimizer();
      },
    })
  });

  // Prevent default for confirm update modal
  $('#confirmUpdatePortfoliosBtn').click(function(ev) {
    ev.preventDefault();
  })


  // Init companies bloodhound -- must do separately to avoid including brands
  const companiesBloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: 'https://' + mainEthosUrl + '/companies/search?restrict_assets=true&query=%QUERY',
      wildcard: "%QUERY",
      transform: function(d) { return d.companies; }
    }
  });
  companiesBloodhound.initialize();

  // Initiate companies typeahead as one
  $('.companies-filter').typeahead({
    hint: true,
    highlight: true,
    minLength: 2,
  }, {
    name: 'companies',
    display: 'name',
    limit: 44,
    source: companiesBloodhound.ttAdapter(),
    templates: {
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return logoTemplate(el.name, el.symbol, el.logo_url);
        }
      }
    },
  });

  // Tracking error option: https://twitter.github.io/typeahead.js/examples/
  const substringMatcher = function(strs) {
    return function findMatches(q, cb) {
      let matches = [];
      let substrRegex = new RegExp(q, 'i');
      $.each(strs, function(i, str) {
        if (substrRegex.test(str)) {
          matches.push(str);
        }
      });
      cb(matches);
    };
  };
  const trackingErrorOpts = ['Positive only', 'Positive or negative'];
  $('.tracking-error-opt-filter').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'tracking_error_option',
    source: substringMatcher(trackingErrorOpts)
  });
  const startWithExistingOpts = ['Start with existing model', 'Start with index (trade in cash)'];
  $('.start-with-existing-filter').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'start_with_existing',
    source: substringMatcher(startWithExistingOpts)
  });
  const taxLossOpts = ['Harvest tax losses', 'Realize tax gains', 'Do not consider tax losses or gains'];
  $('.tax-loss-opt-filter').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'tax_loss_option',
    source: substringMatcher(taxLossOpts)
  });
  const taxTeOpts = ['Tracking error is more important', 'Tax is more important'];
  $('.tax-te-opt-filter').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'tax_te_option',
    source: substringMatcher(taxTeOpts)
  });
  const allowFractionalShares = ['Allow fractional shares', 'Do not allow fractional shares'];
  $('.allow-fractional-shares').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'allow_fractional_shares',
    source: substringMatcher(allowFractionalShares)
  });
  const numberStocksOption = ['Set specific number of stocks', 'Let Optimizer set number of stocks'];
  $('.number-stocks-option').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'number_stocks_option',
    source: substringMatcher(numberStocksOption)
  });
  const handleFunds = ['Replace all funds with stocks', 'Replace funds in line with tax preferences', 'Do not replace any funds'];
  $('.handle-funds').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'handle_funds',
    source: substringMatcher(handleFunds)
  });
  const includeExistingFunds = ['Build with existing funds', 'Build without existing funds'];
  $('.include-existing-funds').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'include_existing_funds',
    source: substringMatcher(includeExistingFunds)
  });
  const restrictToAdrs = ['Restrict to US and ADRs', 'Do not restrict to US and ADRs'];
  $('.restrict-to-adrs').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'restrict_to_adrs',
    source: substringMatcher(restrictToAdrs)
  });
  const rebalanceFrequency = ['Monthly - end of month', 'Monthly - start of month', 'Quarterly - end of quarter', 'Quarterly - start of quarter', 'Half-year - end of half-year', 'Half-year - start of half-year', 'Annual - end of year', 'Annual - start of year'];
  $('.rebalance-frequency-filter').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    name: 'rebalance_frequency',
    limit: 44,
    source: substringMatcher(rebalanceFrequency)
  });

  // Screens typeahead
  screensLiteTypeaheadOptimizer();
  screensTypeaheadOptimizer();
  screenSetsTypeahead();

  // Other filter typeaheads
  filterTypeahead('.funds-symbol-filter', 'funds', '/funds/search.json?country=all&restrict_assets=true&', 45, 'logo');
  filterTypeahead('.index-filter', 'index', '/companies/attrs/search_indices.json?', 43, 'generic');
  filterTypeahead('.impact-causes-name-short-filter', 'causes', '/causes/search.json?', 100, 'cause');
  filterTypeahead('.clients-name-filter', 'clients', '/' + teamUrl + '/clients/search.json?', 222, 'generic');

  // Create a filter on select
  $('.research-filters-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set value
    let value;
    if (typeof suggestion.symbol !== 'undefined') {
      value = suggestion.symbol;
    } else if (typeof suggestion.is_screen !== 'undefined') {
      value = suggestion.key;
    } else if (typeof suggestion.is_screen_set !== 'undefined') {
      value = suggestion.name;
    } else if (typeof suggestion.name !== 'undefined') {
      value = suggestion.value;
    } else if (typeof suggestion.value !== 'undefined') {
      value = suggestion.value;
    } else {
      value = suggestion;
    }

    // Continue if suggestion present
    if ((typeof value !== 'undefined') && (value !== null)) {

      // Set filter scope
      let filter = $(this).attr('data-filter');

      // Create filter
      createFilter($(this), value);

    }

    // Blur
    $(this).blur();

  });

  // Create/update a filter on typeahead select
  $('.company-data-typeahead').bind('typeahead:beforeselect', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Return if has class disabled
    if ($(this).hasClass('disabled')) {return;}

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set value to symbol if a fund; otherwise value
    let val;
    if ( (suggestion.record_type == 'funds') || (suggestion.record_type == 'companies') || (suggestion.record_type == 'currencies')) {
      val = suggestion.symbol;
    } else {
      val = suggestion.value;
    }

    // Create/update filter
    createFilter($(this), val);

    // Blur typeahead
    $(this).blur();

  });

  // Create/update a filter on include companies typeahead select
  $('.include-companies-filter').bind('typeahead:beforeselect', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Return if has class disabled
    if ($(this).hasClass('disabled')) {return;}

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set value to symbol if a fund; otherwise value
    let val = suggestion.symbol;

    // Set weight
    let weight = $('.included-company-weight[data-symbol=\'' + val + '\']').val();
    if (typeof weight === 'undefined') {weight = 5;}

    // Create/update filter
    createFilter($(this), [val, weight]);

    // Blur typeahead
    $(this).blur();

  });

  // Adjust filter on weight change of included company
  $('.included-company-weight, .included-index-weight').bind('blur', function(ev) {

    // Prevent default
    ev.preventDefault();

    // Return if has class disabled
    if ($(this).hasClass('disabled')) {return;}

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set weight and symbol
    let weight = $(this).val();
    let symbol = $(this).attr('data-symbol');

    // Create/update filter
    createFilter($(this), [symbol, weight]);

  });

  // Create/update a filter on include companies typeahead select
  $('.include-index-filter').bind('typeahead:beforeselect', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Return if has class disabled
    if ($(this).hasClass('disabled')) {return;}

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set value to symbol if a fund; otherwise value
    let val = suggestion.symbol;

    // Set current weight
    let weight = 0;
    $('.included-index-weight').each(function() {
      weight += $(this).val();
    });
    weight = 100 - weight;

    // Create/update filter
    createFilter($(this), [val, weight]);

    // Blur typeahead
    $(this).blur();

  });

  // Remove a model filter
  $('.remove-model-filter').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // Set el
    let el = $(this);

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Add spinner and opacity
    Spinner($('#portfolioAssetsCard'));
    $('#portfolio_model_items_body').css('opacity', 0.25);
    el.closest('.applied-filters').css('opacity', 0.25);

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['filter_value'] = el.attr('data-filter-value');
    let filterId = el.attr('data-filter-id');
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/filters/' + filterId + '?' + $.param(params);

    // Create/remove a filter for model
    $.ajax({
      type: 'DELETE',
      url: url,
      dataType: 'script',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 

        // Update associated inputs if a mix filter
        if (el.hasClass('remove-mix-filter')) {
          let cb = el.closest('.card-body');
          cb.find('input').val('');
          cb.find('.mix-info').removeClass('d-none');
          cb.find('.remove-mix-container').remove();
        }

        // Reload table data
        $('#portfolio_model_items_table').DataTable().ajax.reload();

        // Re-enable typeaheads and add filters
        $('.model-interaction').removeClass('disabled');

        // Set unsaved changes to true
        $('.save-portfolio-model').attr('data-unsaved-changes', true);

      },
    });

  });

  // Import assets
  $('.import-assets').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // If model is reusable and portfolio is present, double check that
    // user wants to update underlying model
    let url = 'https://' + mainEthosUrl + "/" + teamUrl + "/models/" + modelId + "/imports/new";
    let params = {};
    let display = $(this).attr('data-display');
    let back_path = $(this).attr("data-back-path");
    let assets_scope = $(this).attr("data-assets-scope");
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    if (typeof display !== 'undefined') {params['display'] = display;}
    if (typeof back_path !== 'undefined') {params['back_path'] = back_path;}
    if (typeof assets_scope !== 'undefined') {params['assets_scope'] = assets_scope;}
    let href = url + '?' + $.param(params);
    Turbolinks.visit(href);
  });

  // Reblance weights to 100%
  $('.rebalance-portfolio-model').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // Hide tooltip
    $(this).tooltip('hide');

    // Add spinner and opacity to table
    Spinner($('#portfolioAssetsCard'));
    $('#portfolio_model_items_body').css('opacity', 0.25);

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['portfolio_model_filter'] = {}
    params['portfolio_model_filter']['model_id'] = modelId;
    params['scope'] = $(this).attr('data-scope');
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/rebalance?' + $.param(params);

    // Create a filter for model
    $.ajax({
      type: 'PATCH',
      url: url,
      dataType: 'script',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 

        // Reload table data
        $('#portfolio_model_items_table').DataTable().ajax.reload();

        // Re-enable typeaheads and add filters
        $('.model-interaction').removeClass('disabled');

        // Set unsaved changes to true
        $('.save-portfolio-model').attr('data-unsaved-changes', true);
      },
    });

  });

  // Don't submit forms when hit enter on search bars
  $('.card-table-search').keypress(function (e) {
    if (e.which == 13) {
      e.preventDefault();
      return false;
    }
  });


  // Empty typeaheads on select
  $('.research-filters-typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) {
    $(this).typeahead('val', '');
  });

  $('.research-filters-typeahead').blur(function() {
    $(this).typeahead('val', '');
  });


  // Set size of account
  $('.value-of-account').number( true, 0 );
  $('.value-of-account').on("change paste keyup", function() {

    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;

    // Notify user if difficult weight/count combination
    let numericVal = parseFloat( $(this).val() );

    if (Number.isNaN(numericVal)) {
      numericVal = 1;
    } else if (numericVal < 1) {
      numericVal = 100000;
      ToastCustom('Too small', 'Value must be greater than 0', 3000);
    } else if (numericVal >= 1000000000000) {
      numericVal = 100000;
      ToastCustom('Too large', 'Max value is $999,999,999,999', 3000);
    }

    // Set formatted string to return
    $('.value-of-account').val('$' + numericVal.toString());

  });

  $('.value-of-account').on("blur", function() {

    // Create/update filter
    let value = $(this).val();
    createFilter( $('.value-of-account'), value, "");

  });


  // Build stocks count slider
  let stocksMax = $('#stocksCount').attr('data-stocks-max');
  $('#stocksCount').ionRangeSlider({
    type: "single",
    min: 25,
    max: stocksMax,
    from: 10,
    to: stocksMax,
    step: 1,
    grid_num: 1,
    onFinish(data) {

      // Notify user if difficult weight/count combination
      let stocksCount = data.from;
      let minWeight = $('#minMaxWeightSlider').attr('data-from');
      let weightCountRatio = stocksCount * minWeight;

      if (weightCountRatio > 100) {

        // Set max count with current min
        let maxCount = 100 / minWeight;
        let notice = 'Number of stocks times min weight must be less than 100. Lower your min weight to enable a higher count.';

        // Create filter with notice
        createFilter( $('#stocksCount'), maxCount, notice )

      } else if (weightCountRatio > 50) {

        let maxRecommended = (50 / stocksCount).toFixed(1);
        maxRecommended = (maxRecommended - 0.1).toFixed(1)
        let notice = 'Option updated, though we recommend a minimum weight of ';
        notice += maxRecommended;
        notice += '% or lower to reduce tracking error.'

        // Create filter with notice
        createFilter( $('#stocksCount'), data.from, notice )

      } else {

        // Create filter
        createFilter( $('#stocksCount'), data.from )

      }

    }
  });

  // Build max tracking error
  $('#maxTrackingError').ionRangeSlider({
    type: "single",
    min: 0.5,
    max: 10,
    from: 0.5,
    to: 10,
    step: 0.1,
    grid_num: 1,
    postfix: "%",
    onFinish(data) {
      createFilter( $('#maxTrackingError'), data.from )
    }
  });

  // Build max sector error
  $('#maxSectorError').ionRangeSlider({
    type: "single",
    min: 2,
    max: 30,
    from: 2,
    to: 30,
    step: 1,
    grid_num: 1,
    postfix: "%",
    onFinish(data) {
      createFilter( $('#maxSectorError'), data.from )
    }
  });

  // Build months horizon
  $('#monthsHorizon').ionRangeSlider({
    type: "single",
    min: 0,
    max: 48,
    from: 0,
    to: 48,
    step: 1,
    grid_num: 1,
    onFinish(data) {
      createFilter( $('#monthsHorizon'), data.from )
    }
  });

  // Build max tracking error
  let taxValue = parseFloat($('#maxTaxLoss').attr("data-from"));
  let stepValue;
  if (taxValue <= 100000) {
    stepValue = 1000; 
  } else if (taxValue <= 10000000) {
    stepValue = 10000;
  } else {
    stepValue = 100000;
  }
  $('#maxTaxLoss').ionRangeSlider({
    type: "single",
    min: 0,
    max: taxValue,
    from: 0,
    to: taxValue,
    step: stepValue,
    grid_num: 1,
    prefix: "$",
    prettify_separator: ",",
    onFinish(data) {
      createFilter( $('#maxTaxLoss'), data.from )
    }
  });

  // Build max tracking error
  $('#maxTaxGain').ionRangeSlider({
    type: "single",
    min: 0,
    max: taxValue,
    from: 0,
    to: taxValue,
    step: stepValue,
    grid_num: 1,
    prefix: "$",
    prettify_separator: ",",
    onFinish(data) {
      createFilter( $('#maxTaxGain'), data.from )
    }
  });

  // Build stocks count slider
  $('#minRating').ionRangeSlider({
    type: "single",
    min: 0,
    max: 75,
    from: 0,
    to: 75,
    step: 1,
    grid_num: 1,
    onFinish(data) {
      createFilter( $('#minRating'), data.from )
    }
  });

  // Build min/max weight
  $('#minMaxWeightSlider').ionRangeSlider({
    type: "double",
    min: 0.01,
    max: 25,
    from: 0.01,
    to: 25,
    step: 0.1,
    grid_num: 1,
    postfix: "%",
    onFinish(data) {

      // Notify user if difficult weight/count combination
      let stocksCount = $('#stocksCount').attr('data-from');
      let minWeight = data.from;
      let weightCountRatio = stocksCount * minWeight;

      if (weightCountRatio > 100) {
        ToastCustom('Impossible conditions', 'Number of stocks times min weight must be less than 100.', 5000);

      } else if (weightCountRatio > 50) {

        let maxRecommended = (50 / stocksCount).toFixed(1);
        maxRecommended = (maxRecommended - 0.1).toFixed(1)
        let notice = 'Option updated, though we recommend a minimum weight of ';
        notice += maxRecommended;
        notice += '% or lower to reduce tracking error.'

        // Create filter with notice
        createFilter( $('#minMaxWeightSlider'), [data.from, data.to], notice )

      } else {

        // Create filter now, after checks
        createFilter( $('#minMaxWeightSlider'), [data.from, data.to] )

      }

    }
  });

  // Remove opaque class to show sliders (starts as opaque to hide occasional jumping while JS initiating above)
  $('.slider-mix-body').removeClass('opaque');

  // Tear down sliders before cache
  $(document).on("turbolinks:before-cache", function() {
    let $sliderExpense = $('#expenseRatio').data('ionRangeSlider');
    let $sliderAssets = $('#totalAssets').data('ionRangeSlider');
    if ($sliderExpense !== null && typeof $sliderExpense !== 'undefined') { $sliderExpense.destroy(); }
    if ($sliderAssets !== null && typeof $sliderAssets !== 'undefined') { $sliderAssets.destroy(); }
  });
 
  // On display of modals
  $('.modal').on('shown.bs.modal', function() {
    $(this).find('input').focus();
  })

}
