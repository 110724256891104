// Base typeahead class
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default class {

  constructor(el) {
    this.el = el;
  }

  initializeBloodhound(recordType) {

    // Set url parameters for CORS requests to main Ethos site
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    const bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: 'https://' + mainEthosUrl + '/' + recordType + '/search',
        prepare: function (query, settings) {
          settings.url = settings.url + '?query=' + query
          settings.headers = {
            "Content-Type": "application/json",
            "Ethos-Cors-Key": ethosCorsKey,
            "Session-Token": sessionToken,
            "Email": userEmail
          };
          return settings;
        }
      }});

    bloodhound.initialize();
    return bloodhound;

  }

  render(bloodhound, limit = 5, autocomplete = 'off', minLength = 0) {

    let emptyTemplate = '<div class="empty-message">Unable to find anything</div>';

    let logoDisplayTemplate = (name, symbol, logo_url) =>
      '<div><div class="d-inline-block mr-3">' + 
      '<img alt="logo" width="30px" src="' + logo_url + '"></div>' +
      '<div class="d-inline-block">' + name + ' (' + symbol + ')</div></div>';

    let genericDisplayTemplate = (name, symbol) =>
      '<div><div class="d-inline-block">' + name + ' (' + symbol + ')' + '</div></div>';

    let causeTemplate = (name, fa_key, keywords) => 
      '<div class="d-flex mr-2">' + 
        '<div class="mr-3 mt-1" width="25px"><i class="fad fa-fw fa-' + fa_key + '"></i></div>'+
        '<div class="d-flex flex-column">' + 
          '<div class="font-weight-normal">' + name + '</div>' +
          '<div class="text-gray500">' + keywords + '</div>' +
        '</div>' +
      '</div>';

    let articleTemplate = (title, category) => 
      '<div><div class="d-inline-block">' + title +
      '<span class="text-gray500">' + ' (' + category + ')</div></div>';

    let providersTemplateLogo = (name, logo_url, base_url) => 
      '<div class=\'d-flex\'><div class="mr-3">' + 
      '<img alt="logo" width="30px" src="' + logo_url + '"></div>' +
      '<div>' + name + ' <span class=\'text-gray500\'>(' + base_url + ')</span></div></div>';

    let providersTemplateBase = (name, base_url) => 
      '<div class=\'d-flex\'><div class="mr-3">' + 
      '<div style=\'width: 30px;\'><i class="fad fa-fw fa-2x fa-university"></i></div></div>'+
      '<div class="d-inline-block">' + name + ' <span class=\'text-gray500\'>(' + base_url + ')</span></div></div>';

    // // Turn off autocomplete for companies typeahead (first input on landing page); 
    // // Have to do here, chrome ignoring if autocomplete set in view
    // $('.typeahead').attr('autocomplete', autocomplete);

    $(this.el).typeahead({
      hint: true,
      highlight: true,
      minLength: minLength
    }, {
      name: 'typeahead',
      display: 'name',
      limit: 100,
      source: bloodhound.ttAdapter(),
      templates: {
        empty: emptyTemplate,
        suggestion(el) {

          if (el.name === null) {
            return emptyTemplate;

          } else if (typeof el.fa_key !== 'undefined') {
            return causeTemplate(el.name_short, el.fa_key, el.keywords);

          } else if (typeof el.title !== 'undefined') {
            return articleTemplate(el.title, el.category);

          } else if (typeof el.base_url !== 'undefined' && el.logo_url === 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG') {
            // If logo url is Yodlee default logo, show an icon instead
            // Yodlee default logo showing up as broken icon
            return providersTemplateBase(el.name, el.base_url);

          } else if (typeof el.base_url !== 'undefined' && typeof el.logo_url !== 'undefined') {
            return providersTemplateLogo(el.name, el.logo_url, el.base_url);

          } else if (typeof el.base_url !== 'undefined') {
            return providersTemplateBase(el.name, el.base_url);

          } else if ((el.logo_url !== null) && (el.logo_url.length > 0)) {
            return logoDisplayTemplate(el.name, el.symbol, el.logo_url);

          } else {
            return genericDisplayTemplate(el.name, el.symbol);
          }

        }
      }
    });
  }

}
