import Spinner from 'src/javascripts/components/utilities/Spinner';
import ResearchTable from 'src/javascripts/components/tables/ResearchTable';
import {companiesBloodhound, filterTypeahead, screensTypeahead, screensLiteTypeahead, screenSetsTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Set constants
  const teamUrl = $('#researchResultsCard').attr('data-team-url');
  const userId = $('#researchResultsCard').attr('data-user-id');
  const modelId = $('#researchResultsCard').attr('data-model-id');
  const portfolioId = $('#researchResultsCard').attr('data-portfolio-id');
  const reloadUrl = $('#researchResultsCard').attr('data-reload-url');
  const researchScope = $('#researchResultsCard').attr('data-research-scope');
  const assetsScope = $('#researchResultsCard').attr('data-assets-scope');
  const shouldUpdateModel = $('#researchResultsCard').attr('data-should-update-model');

  // Set CORS variables
  let sessionToken = $('body').attr('data-session-token');
  let userEmail = $('body').attr('data-email');
  let ethosCorsKey = $('body').attr('data-ethos-cors-key');
  let mainEthosUrl = $('body').attr('data-main-ethos-url');

  // Add spinner and opacity
  Spinner($('#researchResultsCard'));
  $('#researchResultsCard .card-body').css('opacity', 0.25);

  // Set function to reload page with params
  const reloadResearch = function(notice = "") {

    // Set params and reload
    let url = reloadUrl;
    let params = {};

    // Re-set model id in case changed
    params['model_id'] = $('#researchResultsCard').attr('data-model-id');
    let assetsView = $('#portfolio_model_items_table').attr('data-assets-view');
    let fullView = $('#portfolio_model_items_table').attr('data-full-view');

    // Set other potential attrs
    if (typeof fullView !== 'undefined') {params['full_view_model'] = fullView;}
    if (typeof assetsView !== 'undefined') {params['assets_view'] = assetsView;}
    if (typeof assetsScope !== 'undefined') {params['assets_scope'] = assetsScope;}
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    if (notice.length !== 0) {params['notice'] = notice;}

    // Set closed/collapsed cards, if present
    let toClose = [];
    if ((researchScope === 'companies') || (researchScope === 'all')) {
      if ($('#savedQueriesCard').hasClass('minimized')) {toClose.push('#savedQueriesCard')}
      if ($('#compareRecordsCard').hasClass('minimized')) {toClose.push('#compareRecordsCard')}
      if ($('#screenCompaniesCard').hasClass('minimized')) {toClose.push('#screenCompaniesCard')}
      if ($('#sortCompaniesCard').hasClass('minimized')) {toClose.push('#sortCompaniesCard')}
      if ($('#filterClassificationCard').hasClass('minimized')) {toClose.push('#filterClassificationCard')}
      if ($('#filterSizeCard').hasClass('minimized')) {toClose.push('#filterSizeCard')}
      if ($('#filterGeographyCard').hasClass('minimized')) {toClose.push('#filterGeographyCard')}
      if ($('#filterIndicesCard').hasClass('minimized')) {toClose.push('#filterIndicesCard')}
    } 
    if ((researchScope === 'funds') || (researchScope === 'all')) {
      if ($('#savedQueriesCard').hasClass('minimized')) {toClose.push('#savedQueriesCard')}
      if ($('#compareRecordsCard').hasClass('minimized')) {toClose.push('#compareRecordsCard')}
      if ($('#screenFundsCard').hasClass('minimized')) {toClose.push('#screenFundsCard')}
      if ($('#sortFundsCard').hasClass('minimized')) {toClose.push('#sortFundsCard')}
      if ($('#filterFundTypeCard').hasClass('minimized')) {toClose.push('#filterFundTypeCard')}
      if ($('#filterFundFinancialsCard').hasClass('minimized')) {toClose.push('#filterFundFinancialsCard')}
      if ($('#filterAdvocacyCard').hasClass('minimized')) {toClose.push('#filterAdvocacyCard')}
      if ($('#filterFundHoldingsCard').hasClass('minimized')) {toClose.push('#filterFundHoldingsCard')}
    }
    params["to_close"] = JSON.stringify(toClose);

    // Reload with params
    return Turbolinks.visit(url + '?' + $.param(params));

  }

  // Set function to create a filter
  const createFilter = function(el, suggestion, notice = "") {

    // Add to assets card if for cause, client or metric
    let c = el.closest('.card'); 
    Spinner(c);
    $(c).find('.card-body').css('opacity', 0.25);

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['portfolio_model_filter'] = {}
    params['portfolio_model_filter']['model_id'] = modelId;
    params['portfolio_model_filter']['filter_table'] = el.attr('data-filter-table');
    params['portfolio_model_filter']['filter_property'] = el.attr('data-filter-property');
    params['portfolio_model_filter']['filter_calculation'] = el.attr('data-filter-calculation');
    params['portfolio_model_filter']['filter_value'] = suggestion;
    params['update_model'] = shouldUpdateModel // Add update model false to avoid updating model items with this call
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/filters?' + $.param(params);

    // Create a filter for model
    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'application/json',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 

        // Reload page
        reloadResearch(notice);

      },
    });

  }

  // Init bloodhounds
  companiesBloodhound.initialize();

  // Init typeaheads
  screensTypeahead();
  screenSetsTypeahead();
  screensLiteTypeahead();
  filterTypeahead('.models-filter', 'models', '/' + teamUrl + '/models/search.json?', 122, 'generic');
  filterTypeahead('.impact-causes-name-short-filter', 'causes', '/causes/search.json?', 100, 'cause');
  filterTypeahead('.metric-filter', 'metrics', '/metrics/search.json?', 550, 'cause');
  filterTypeahead('.clients-name-filter', 'clients', '/' + teamUrl + '/clients/search.json?', 45, 'generic');

  if ((researchScope === 'companies') || (researchScope === 'all')) {
    filterTypeahead('.index-filter', 'index', '/companies/attrs/search_indices.json?', 43, 'generic');
    filterTypeahead('.sector-filter', 'sectors', '/companies/attrs/search.json?attr=sector&', 40, 'generic');
    filterTypeahead('.industry-filter', 'industries', '/companies/attrs/search.json?attr=industry&', 115, 'generic');
    filterTypeahead('.peer-group-filter', 'peer_groups', '/companies/attrs/search.json?attr=peer_group&', 500, 'generic');
    filterTypeahead('.region-filter', 'regions', '/companies/attrs/search.json?attr=region&', 18, 'generic');
    filterTypeahead('.hq-country-filter', 'countries', '/companies/attrs/search.json?attr=hq_country&', 200, 'generic');
    filterTypeahead('.hq-state-filter', 'states', '/companies/attrs/search.json?attr=hq_state&', 250, 'generic');
    filterTypeahead('.hq-city-filter', 'cities', '/companies/attrs/search.json?attr=hq_city&', 500, 'generic');
    filterTypeahead('.companies-symbol-filter', 'companies', '/companies/search_share_classes.json?', 168, 'logo');
  } 

  if ((researchScope === 'funds') || (researchScope === 'all')) {
    filterTypeahead('.funds-symbol-filter', 'funds', '/funds/search_share_classes.json?', 145, 'logo');
    filterTypeahead('.category-filter', 'categories', '/funds/attrs/search.json?attr=category&', 514, 'generic');
    filterTypeahead('.asset-class-filter', 'asset_classes', '/funds/attrs/search.json?attr=asset_class&', 142, 'generic');
    filterTypeahead('.country-filter', 'countries', '/funds/attrs/search.json?attr=country&', 24, 'generic');
    filterTypeahead('.geography-filter', 'geographies', '/funds/attrs/search.json?attr=geography&', 54, 'generic');
    filterTypeahead('.fund-family-filter', 'fund_family', '/funds/attrs/search.json?attr=fund_family&', 588, 'generic');

    // Initiate companies typeahead as one
    $('.include-holding-filter, .exclude-holding-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'companies',
      display: 'name',
      limit: 44,
      source: companiesBloodhound.ttAdapter(),
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return logoTemplate(el.name, el.symbol, el.logo_url);
          }
        }
      },
    });

  }

  if (researchScope === 'all') {
    filterTypeahead('.currency-symbol-filter', 'currencies', '/currencies/search?', 115, 'generic');
  }

  // On typeahead select, create a filter
  $('.research-filters-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Return if has class disabled
    if ($(this).hasClass('disabled')) {return;}

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set value to symbol if a fund; otherwise value
    let val;
    if (typeof suggestion.symbol !== 'undefined') {
      val = suggestion.symbol;
    } else if (typeof suggestion.is_screen !== 'undefined') {
      val = suggestion.key;
    } else if (typeof suggestion.is_screen_set !== 'undefined') {
      val = suggestion.name;
    } else if (typeof suggestion.name !== 'undefined') {
      val = suggestion.value;
    } else if (typeof suggestion.value !== 'undefined') {
      val = suggestion.value;
    } else {
      val = suggestion;
    }

    // Create/update filter
    createFilter($(this), val);

    // Blur typeahead
    $(this).blur();

  });

  // Create/update a filter on include companies typeahead select
  $('.include-index-filter').bind('typeahead:beforeselect', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Return if has class disabled
    if ($(this).hasClass('disabled')) {return;}

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set value to symbol if a fund; otherwise value
    let val = suggestion.symbol;

    // Set weight
    let weight = $('.included-index-weight[data-symbol=\'' + val + '\']').val();
    if (typeof weight === 'undefined') {weight = 5;}

    // Create/update filter
    createFilter($(this), [val, weight]);

    // Blur typeahead
    $(this).blur();

  });

  // On typeahead select, create a model item and update associated filter
  $('.add-model-item-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Return if has class disabled
    if ($(this).hasClass('disabled')) {return;}

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set value to symbol if a fund; otherwise value
    let val = suggestion.value;

    // Add to assets card if for cause, client or metric
    let c = $(this).closest('.card'); 
    Spinner(c);
    $(c).find('.card-body').css('opacity', 0.25);

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['model_id'] = modelId;
    params['item_type'] = $(this).attr('data-item-type');
    params['item_ids'] = [val];
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/items?' + $.param(params);

    // Create a filter for model
    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'application/json',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 

        // Reload page
        reloadResearch("");

      },
    });

    // Blur typeahead
    $(this).blur();

  });

  // Reload with different view
  $('#assetsView').change(function() {
    let c = $(this).closest('.card'); 
    Spinner(c);
    $(c).find('.card-body').css('opacity', 0.25);
    let viewType = $(this).val().toLowerCase();
    $('#portfolio_model_items_table').attr('data-assets-view', viewType);
    reloadResearch("");
  });

  // On click of a filter
  $('.filter-results').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Temporarily disable typeahead and add filter buttons
    $('.model-interaction').addClass('disabled');

    // Set value
    let val = $(this).attr('data-value');

    // Create/update filter
    createFilter($(this), val);

  });

  // On click of remove filter, e.g., market cap
  $('.remove-filter').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // Add to assets card if for cause, client or metric
    let c = $(this).closest('.card'); 
    Spinner(c);
    $(c).find('.card-body').css('opacity', 0.25);

    // Set filter and value to remove
    let filterId = $(this).attr('data-filter-id');
    let value = $(this).attr('data-value');

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['filter_value'] = value;
    params['update_model'] = shouldUpdateModel // Add update model false to avoid updating model items with this call
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/filters/' + filterId + '?' + $.param(params);

    // Create a filter for model
    $.ajax({
      type: 'DELETE',
      dataType: 'application/json',
      url: url,
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 
        reloadResearch("");
      },
    });

  });

  // Remove a filter on button select, if present
  $('.remove-model-item').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // Add to assets card if for cause, client or metric
    let c = $(this).closest('.card'); 
    Spinner(c);
    $(c).find('.card-body').css('opacity', 0.25);

    // Build ajax call to add/remove selection from model filters
    let params = {};
    params['item_type'] = $(this).attr('data-item-type');
    if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
    let itemId = $(this).attr('data-item-id');
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/items/' + itemId + '?' + $.param(params);

    // Create/remove a filter for model
    $.ajax({
      type: 'DELETE',
      url: url,
      dataType: 'script',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 
        reloadResearch("");
      },
    });
  });


  // Set research table
  $('.research-table').each(function() {
    let tableId = $(this).attr('id');
    let table = new ResearchTable($('#' + tableId));
    $.when( table.render(tableId) ).then( table.setUp(tableId) );
  });

  // Clear all model filters
  $('.clear-all-filters').click(function(ev) {

    // Prevent default
    ev.preventDefault();

    // Hide tooltip
    $(this).tooltip('hide');

    // Build ajax call to add/remove selection from model filters
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/filters/destroy_all';
    let params = {};
    params['update_model'] = shouldUpdateModel // Don't update model items
    params['scope'] = 'all' // Delete all model filters
    url = url + '?' + $.param(params);

    // Add spinner and opacity to table
    Spinner($('#researchResultsCard'));
    $('#researchResultsCard .card-body').css('opacity', 0.25);

    $.ajax({
      type: 'DELETE',
      dataType: "application/json",
      url: url,
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      complete(result) { 
        reloadResearch("");
      },
    });

  });

  // Update model name/description
  $('.update-model-info').click(function() {
    let nameVal = $('#reusableModelNameInput').val();
    let descVal = $('#reusableModelDescInput').val();
    let cp = $('body').attr('data-controller-path');
    let params = {};
    params['portfolio_model'] = {};
    params['portfolio_model']['name'] = nameVal;
    params['portfolio_model']['description'] = descVal;
    params['portfolio_model']['created_status'] = 'saved';
    params['portfolio_model']['user_id'] = userId;
    let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/models/' + modelId + '/update_attrs?' + $.param(params);
    $.ajax({
      type: 'PATCH',
      url: url,
      dataType: 'application/json',
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      complete(result) { 
        // Close modal
        $('#updateModelInfoModal').modal('hide');
        // Notify that updated
        if (cp === 'teams-research') {
          return reloadResearch("Query saved and available to be re-used");
        } else {
          return reloadResearch("");
        }
      },
    })
  });

  // On select of a reusable model / saved query
  $('.models-filter').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {

    // Prevent default
    ev.preventDefault();

    // Hide tooltip
    $('.tooltip').tooltip('hide');

    // Set value to symbol if a fund; otherwise value
    let val = suggestion.value;

    // Update model id attr on research results card
    $('#researchResultsCard').attr('data-model-id', val);

    // Blur typeahead
    $(this).blur();

    // Reload
    return reloadResearch("");

  });


  // Build expense ratio slider
  if ($('#expenseRatio').length !== 0) {
    $('#expenseRatio').ionRangeSlider({
      type: "double",
      grid: false,
      min: 0,
      max: 2,
      from: 0,
      to: 2,
      step: 0.01,
      grid_num: 1,
      postfix: "%",
      hide_min_max: true,
      onFinish(data) {

        // Set value (range of expense ratio)
        let val = [data.from, data.to]

        // Create/update filter
        createFilter($('#expenseRatio'), val);

        // Reload page
        reloadResearch("");

      }
    });
  }

  // Build expense ratio slider
  if ($('#totalAssets').length !== 0) {
    $('#totalAssets').ionRangeSlider({
      type: "double",
      grid: false,
      min: 0,
      max: 10,
      from: 0,
      to: 10,
      step: 0.1,
      grid_num: 1,
      prettify_separator: ",",
      prefix: "$",
      postfix: "+",
      hide_min_max: true,
      onFinish(data) {

        // Set value (range of expense ratio)
        let val = [data.from, data.to]

        // Create/update filter
        createFilter($('#totalAssets'), val);

        // Reload page
        reloadResearch("");

      }
    });
  }

  // Remove opaque class to show sliders (starts as opaque to hide occasional jumping while JS initiating above)
  $('.slider-mix-body').removeClass('opaque');

  // Tear down sliders before cache
  $(document).on("turbolinks:before-cache", function() {
    let $sliderExpense = $('#expenseRatio').data('ionRangeSlider');
    let $sliderAssets = $('#totalAssets').data('ionRangeSlider');
    if ($sliderExpense !== null && typeof $sliderExpense !== 'undefined') { $sliderExpense.destroy(); }
    if ($sliderAssets !== null && typeof $sliderAssets !== 'undefined') { $sliderAssets.destroy(); }
    $('.spinner-container').remove();
    $('.card-body').css('opacity', 1);
    $('.disabled').removeClass('disabled');
  });
 
}