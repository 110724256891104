import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class PortfolioScreensTableFund extends Table {

  render(tableId) {

    // Set cols
    const cols = GetCols(this.el);

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Render table
    const table = $('#' + tableId).DataTable({
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      pageLength: 10,
      scrollx: true,
      dom: 'tp',
      columns: cols,      
      ordering: true,
      order: [[0, 'asc']],
      language: { 
        emptyTable: "No screens information available for this fund. Please contact us with any questions.",
      },
      drawCallback: function( settings ) {

        // Hide paging if total display is equal to or less than page length
        let json = settings.json;
        if (typeof json !== 'undefined') {
          let len = json.recordsTotal;
          if (len < 11) {
            $('#' + tableId + '_paginate').addClass('d-none');
          }
        }

        // Re-init tooltips for lock icons
        $('[data-toggle=tooltip]').tooltip();

      },

    });

    return 'rendered';

  }
};