// Build urls for Api historical data (c3 chart) calls
export default function(chartId, record) {

  // Set el from chartId
  let el = $('#' + chartId);

  // Set record type and id (e.g., companies and id, funds and id, accounts and id)
  let fromDate;
  let recordParams = {"record_type": record[0], "record_id": record[1]}; 

  // Set from date based on data attrs on page, or set as 90 days ago by default
  // To date is always today, handled in Api controllers
  let dateSelectionGroup = el.closest('.chart-section').find('.date-selection-group');
  if (typeof dateSelectionGroup.attr('data-from-date') !== 'undefined') {
    fromDate = dateSelectionGroup.attr('data-from-date');
  } else if (typeof el.attr('data-from-date') !== 'undefined') {
    fromDate = el.attr('data-from-date');
  } else {
    let date = new Date();
    let startDate = new Date(new Date().setDate(date.getDate()-90));
    let fromYear = startDate.getFullYear();
    let fromMonth = ('0' + (startDate.getMonth() + 1)).slice(-2);
    let fromDay = ('0' + startDate.getDate()).slice(-2);
    fromDate = fromYear + '-' + fromMonth + '-' + fromDay;
  }

  // Set selected impact formula based on record or chart data attributes (net impact charts only)
  let formulaId = el.attr('data-formula-id');

  // Set report token if present
  let reportToken = el.attr('data-report-token');

  // Set rebalancing option if present
  let rebalancingOpt = el.attr('data-rebalancing-option');

  // Set return option if present
  let returnOpt = el.attr('data-return-option');

  // Set legend name options
  let legendNameOpt = el.attr('data-legend-names');

  // Set data name, direction and count for formula average calculations
  let dataName = record[2]
  let share = record[3]

  // Build params based on chart data attributes
  let params = {};
  if (typeof fromDate !== 'undefined') { params['from_date'] = fromDate; }
  if (typeof rebalancingOpt !== 'undefined') { params['rebalancing'] = rebalancingOpt; }
  if (typeof returnOpt !== 'undefined') { params['return_option'] = returnOpt; }
  if (typeof reportToken !== 'undefined') { params['token'] = reportToken; }
  if (typeof legendNameOpt !== 'undefined') { params['legend_names'] = legendNameOpt; }
  if (typeof formulaId !== 'undefined') { params['formula_id'] = formulaId; }
  if (typeof share !== 'undefined') { params['share'] = share; }
  if (typeof dataName !== 'undefined') { params['data_name'] = dataName; }

  // Set url based on chart data attr
  let url = el.attr('data-url');

  if (typeof recordParams["record_id"] !== 'undefined') { url = url.replace(':record_id', recordParams["record_id"]); }
  if (typeof recordParams["record_type"] !== 'undefined') { url = url.replace(':record_type', recordParams["record_type"]); }

  return url + '?' + $.param(params);

};
