export default class Help {

  constructor() {}

  render() {

	  // Toggle chevron's on click
	  $('.help-expandable .expand-help-content').click(function() {
	  	$(this).find('i:not(.fa-paper-plane), svg:not(.fa-paper-plane)').toggleClass('fa-chevron-down fa-chevron-up');
	  })

 }

}