import PortfolioControversiesTable from 'src/javascripts/components/tables/PortfolioControversiesTable';
import PortfolioControversiesTableFund from 'src/javascripts/components/tables/PortfolioControversiesTableFund';

export default class TeamsPortfoliosControversiesIndex {

  constructor() {}

  render() {

    // Render table
    if ( $('.dataTables_wrapper').length === 0 ) {
      $('.portfolio-controversies-table').each(function() {
        let tableId = $(this).attr('id');
        let table = new PortfolioControversiesTable($('#' + tableId));
        $.when( table.render(tableId) )
      })
    }

    // Expand holding rows on click
    $('.controversy-title').click(function() {
      let fc = $(this).closest('.holding-controversy-details');
      $('.holding-controversy-details').not(fc).removeClass('expanded');
      fc.toggleClass('expanded');
      fc.find('.controversy-title svg').toggleClass('fa-chevron-right').toggleClass('fa-chevron-down');

      if (fc.hasClass('expanded')) {
        fc.find('.more-info').empty().append("(click to hide)");
        let t = fc.find('.portfolio-controversies-table-fund');
        let tableId = t.attr('id');

        if ( $('#' + tableId + '_wrapper').length === 0 ) {
          let table = new PortfolioControversiesTableFund($('#' + tableId));
          $.when( table.render(tableId) )
        }

      } else {
        fc.find('.more-info').empty().append("(click for more info)");
      }
    })

  }

}