import PortfolioModelEdit from 'src/javascripts/components/PortfolioModelEdit';
import PortfolioModelOptimizer from 'src/javascripts/components/PortfolioModelOptimizer';
import ResearchFiltering from 'src/javascripts/components/ResearchFiltering';

export default class TeamsPortfoliosModelsEdit {

  constructor() {}

  render() {

  	// JS for optimizer or normal modeling  	
  	if ($('#optimizerFilters').length !== 0) {
    	PortfolioModelOptimizer();
  	} else {
    	ResearchFiltering();
    	PortfolioModelEdit();
    }

  }

}