import Spinner from 'src/javascripts/components/utilities/Spinner';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
const Bloodhound = require('src/javascripts/vendor/bloodhound');


export default function() {

  const getNotifications = function() {

    // Set url to get data
    let dataUrl = '/notifications/navbar';

    // Set empty hash to build params
    let params = {};

    // Set record count to load
    params['offset'] = $('.navbar-notifications').attr('data-api-offset');
    params['length'] = $('.navbar-notifications').attr('data-api-length');

    // Execute ajax request to get notifications for current user
    // Must specify '.js' otherwise processes as JSON
    return $.ajax({
      type: "GET",
      dataType: "script",
      url: dataUrl + '.js?' + $.param(params)
    });

  }

  const getAvailableTeams = function() {

    // Set url parameters for CORS requests to main Ethos site
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Open typeahead for teams
    if ($('#navbarUserMenu').find('#available_teams_typeahead').length > 0) {

      let dataUrl = 'https://' + mainEthosUrl + '/available_teams.json';
      const bloodhound = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: dataUrl,
          prepare: function (query, settings) {
            settings.url = settings.url + '?query=' + query
            settings.headers = {
              "Content-Type": "application/json",
              "Ethos-Cors-Key": ethosCorsKey,
              "Session-Token": sessionToken,
              "Email": userEmail
            };
            return settings;
          }
        }});

      bloodhound.initialize();
      let emptyTemplate = '<div class="empty-message">Unable to find that</div>';
      let teamTemplate = (name) => '<div>' + name + '</div>';

      $('#available_teams_typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 0
      }, {
        name: 'typeahead',
        display: 'name',
        limit: 104,
        source: bloodhound.ttAdapter(),
        templates: {
          empty: emptyTemplate,
          suggestion(el) {
            return teamTemplate(el.name);
          }
        }
      });

      $('#available_teams_typeahead').focus();
      $('#available_teams_typeahead').bind('typeahead:select typeahead:autocomplete', function(ev, suggestion) { 
        $('#available_teams_typeahead').typeahead('val','');
        $('#available_teams_typeahead').blur();
        Spinner($('#navbarUserMenu'));
        let url = $('#available_teams_typeahead').attr('data-url');
        let params = {};
        params['team_id'] = suggestion.value;
        $.ajax({ 
          url: url + '?' + $.param(params),
          dataType: "script",
          type: 'PATCH',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))}
        });
      })

    } else {

      // Add spinner to dropdown menu
      Spinner($('#navbarUserMenu'));

      // Set url to get data
      let dataUrl = 'https://' + mainEthosUrl + '/available_teams';

      // Execute ajax request to get notifications for current user
      // Must specify '.js' otherwise processes as JSON
      return $.ajax({
        type: "GET",
        dataType: "script",
        url: dataUrl + '.js'
      });

    }

  }


  // Custom notifications dropdown, bootstrap has issues with clicking inside a dropdown
  // https://stackoverflow.com/questions/25089297/avoid-dropdown-menu-close-on-click-inside/25196101#25196101
  $('.top-nav-dropdown a.nav-link').click(function(e) {

    // Prevent default
    e.preventDefault();

    // Close other menus
    $('.top-nav-dropdown').not($(this).closest('li')).removeClass('show');

    // Open menu
    $(this).parent().toggleClass('show');

    if ($(this).closest('li').attr('id') === 'navbarNotificationsItem') {
    
      // Add spinner to dropdown menu
      Spinner($('#navbarNotificationsMenu'));

      // Get data if shown
      if ( $(this).parent().hasClass('show') ) {
        getNotifications();
      }

    } else if ($(this).closest('li').attr('id') === 'navbarUserItem') {

      // Get data if shown
      if ( $(this).parent().hasClass('show') ) {
        getAvailableTeams();
      }

    }

  });

  $('body').on('click', function (e) {
    
    // Set dropdown item
    let d = $('.top-nav-dropdown');

    // Remove open class if clicked outside the dropdown
    if ( !d.is(e.target) && d.has(e.target).length === 0 && $('.show').has(e.target).length === 0) {
      d.removeClass('show');
    }

  });

}