import introJs from 'intro.js/intro.js';

export default function() {

  // Build tour
  let startHowToModelTour = function() {

  	// Set team
  	const teamUrl = $('body').attr('data-team-url')

    // Remove existing overlay
    $('.introjs-overlay').remove();

    // Temporarily hide all help circle tooltips on page
    $('.help-circle').addClass('d-none');

    // Temporarily change positioning and other css
    $('#setModelInfo').addClass('pt-2');
    $('#assetsPositioning').removeClass('position-absolute');
    $('#portfolioAssetsCard').css('margin-top', '0px');

    // Temporarily disable modals
    $('.modal').on('show.bs.modal', function(e) { preventDefault(e); });

    // Set intro
    let intro = introJs();
    intro.setOptions({
      hidePrev: true,
      hideNext: true,
      showStepNumbers: false,
      scrollTo: 'tooltip',
      disableInteraction: true,
      overlayOpacity: 0.6,
      tooltipPosition: 'bottom'
    });

    // Define step 1 text
    let step1 = "<p><span class=\'font-weight-bold\'>Welcome!</span></p>";
    step1 += "<p>This is our new portfolio model tool. If you have any questions after this tour, please reach out to us at <a href=\'mailto:support@ethosesg.com\'>support@ethosesg.com</a>.</p>";
    step1 += "<p>The first step is to give your model a name and (optionally) a description. These are internal to you (clients don't see them).</p>";

    // Define step 2 text
    let step2 = "<p><span class=\'font-weight-bold\'>Adding assets: import</span></p>";
    step2 += "<p>There are two ways to add assets to a model. The first is with a CSV file.</p>";
    step2 += "<p>Click here to upload assets using a CSV file.</p>";

    // Define step 3 text
    let step3 = "<p><span class=\'font-weight-bold\'>Adding assets: directly</span></p>";
    step3 += "<p>You can also add assets to your model directly.</p>";
    step3 += "<p>Use the options here to sort and filter companies and funds to fit your criteria. For example, sort by a cause to see top-rated funds, then filter by asset class and expense ratio.</p>"
    step3 += "<p>Click on a company or fund under <span class=\'font-weight-normal\'>Select</span> to add it to your model.</p>"

    // Define step 4 text
    let step4 = "<p><span class=\'font-weight-bold\'>Adjust weights</span></p>";
    step4 += "<p>Once you add assets, you'll see them in this table. You can adjust asset weightings here.</p>";

    intro.addSteps([
      {
        element: document.querySelectorAll('#setModelInfo')[0],
        intro: step1,
        position: 'bottom-middle-aligned'
      }, {
        element: document.querySelectorAll('#importAssets')[0],
        intro: step2,
        position: 'bottom-middle-aligned'
      }, {
        element: document.querySelectorAll('#addAssetsSection')[0],
        intro: step3,
        position: 'bottom-middle-aligned'
      }, {
        element: document.querySelectorAll('#assetsPositioning')[0],
        intro: step4,
        position: 'bottom-middle-aligned'
      }
    ]);

    intro.onbeforeexit(function() { 
      
      // Reload page to reset CSS changes
      let modelId = $('#importAssets').attr('data-model-id');
      let portfolioId = $('#importAssets').attr('data-portfolio-id');
      let params = {};
      if (typeof portfolioId !== 'undefined') {params['portfolio_id'] = portfolioId;}
      Turbolinks.visit('/' + teamUrl + '/models/' + modelId + '/edit?' + $.param(params), { action: 'replace' });

    });

    // Start tour
    intro.start();

  }

  $(document).on("turbolinks:before-cache", function() { 

    // Exit tour before navigate away with Turbolinks
    // Doesn't work on back button if we don't do this
    introJs().exit();

    // Hide navbar dropdowns, otherwise reappears 
    // for a second when Turbolinks reloads the page
    $('.top-nav-dropdown').removeClass('show');

  });

  // Start tour on click of 'start tour'
  $('#startHowToModelTour, #portfolioModelingInstructions').click(function(e) {
    e.preventDefault();
    $('html,body').animate({ scrollTop: 0}, 'slow');    
    setTimeout(function() {
      startHowToModelTour();
    }, 700);
  })

}