import PortfolioCarbonFootprintTable from 'src/javascripts/components/tables/PortfolioCarbonFootprintTable';

export default class TeamsPortfoliosTemperatureIndex {

  constructor() {}

  render() {


    // Portfolio items, if present on page
    if ($('#carbon_footprint_details_table').length > 0) {
      let tid = 'carbon_footprint_details_table';
      let fTable = new PortfolioCarbonFootprintTable( $('#' + tid) );
      $.when( fTable.render(tid) ).then( status => fTable.setUp(tid) );

    }

  }

}