import * as d3 from 'd3';
import * as c3 from 'c3';
import Chart from 'src/javascripts/components/Chart';
import SetColors from 'src/javascripts/components/charts/helpers/SetColors';
import SetAxisMin from 'src/javascripts/components/charts/axes/SetAxisMin';
import SetAxisMax from 'src/javascripts/components/charts/axes/SetAxisMax';
import SetAxisTicks from 'src/javascripts/components/charts/axes/SetAxisTicks';
import ReturnsChartTooltip from 'src/javascripts/components/charts/tooltips/ReturnsChartTooltip';
import NoDataMessage from 'src/javascripts/components/alerts/NoDataMessage';
import GetReturnsAll from 'src/javascripts/components/charts/helpers/GetReturnsAll';

export default class ReturnsChart extends Chart {

  render(data) {

    // Show no data message if none available
    // data[1] is the chart data; data[0] is dates array
    if ((data === null) || (typeof data[1] === 'undefined') || (data[1].length <= 1)) { 
      return NoDataMessage(this.el); 
    }

    // Set id
    let chartId = this.el.attr('id');

    // Set colors for chart
    const colors = SetColors(data, chartId);

    // If indicated in chart data, set prices data and add to chart element for inclusion
    // in chart tooltip. Used with company, fund, holding and held company returns charts
    if ($('#' + chartId).attr('data-include-prices') === 'true') {

      // Set prices data for tooltip before transform to returns (next)
      // Drop first item in data, which is dates
      const prices = data.slice(1)    

      // Add prices data to chart element for tooltip -- using chart data for aggregating prices
      // data when add/remove items from chart
      $('#' + chartId).data('prices', prices);

    }

    // Change prices data to daily returns (compared to first price in the array)
    // First arg is dates array from data
    const returns = GetReturnsAll(data[0], data);

    // Set min value for y axis, using returned ajax data or chart data attributes
    // Remove first sub array, which is dates for chart
    // Use returns values for axis (rather than prices)
    const yAxisMin = SetAxisMin([returns[1]], this.el.selector);

    // Set max value for y axis, using returned ajax data or chart data attributes
    // Remove first sub array, which is dates for chart
    // Use returns values for axis (rather than prices)
    const yAxisMax = SetAxisMax([returns[1]], this.el.selector);

    // Set y axis tick values, using value range or chart data attributes
    const yAxisTickValues = SetAxisTicks(yAxisMin, yAxisMax, this.el.selector);

    // Render chart
    var chart = c3.generate({
      bindto: '#' + chartId,
      padding: {top: -10, bottom: -20, left: 0},
      data: {
        x: 'date',
        columns: returns,
        type: 'line',
        colors: colors
      },
      point: {show: false},
      axis: {
        x: {
          show: false,
          type: 'timeseries',
          padding: {left: 0, right: 0}
        },
        y: {
          min: yAxisMin,
          max: yAxisMax,
          inner: true,
          padding: {bottom: 35},
          tick: {
            outer: false,
            values: yAxisTickValues,
            format(v, id, i, j) { return v + '%'; }
          }
        }
      },
      legend: {show: false},
      tooltip: {
        format: {},
        contents(d, defaultTitleFormat, defaultValueFormat, color) {
          return ReturnsChartTooltip(chart, this, d, defaultTitleFormat, defaultValueFormat, 'percentage', 2, color);
        }
      },
      grid: {
        y: {show: false},
        x: {show: false}
      }
    });

    return chart;
  }

};
