export default function() {

  if ($('.generating-screens-message').length > 0) {

    // Start screens status poll if importing
    let screensStatusPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-screens-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set url parameters for CORS requests to main Ethos site
        let sessionToken = $('body').attr('data-session-token');
        let userEmail = $('body').attr('data-email');
        let ethosCorsKey = $('body').attr('data-ethos-cors-key');
        let mainEthosUrl = $('body').attr('data-main-ethos-url');

        // Set Api url to get data
        let teamUrl = isGenerating.attr('data-team-url');
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let token = isGenerating.attr('data-token');
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['token'] = token;
        let url = 'https://' + mainEthosUrl + '/' + teamUrl + '/portfolios/' + portfolioId + '/screens/check_screens_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          beforeSend: function(request) {
            request.setRequestHeader("Content-Type", "application/json");
            request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
            request.setRequestHeader("Session-Token", sessionToken);
            request.setRequestHeader("Email", userEmail);
          },
          url: url,
          complete() {
            setTimeout(function() { screensStatusPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-screens-message').length !== 0) { screensStatusPoll(); }

  }

}