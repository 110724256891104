// Sign up form used on multiple pages
export default function() {

  // On select team type
  $('#team_team_type').change(function() {
    let val = $(this).val();
    if (val.length !== 0) {
      $(this).removeClass('text-gray500');
    } else {
      $(this).addClass('text-gray500');
    }
  });

  // Intl tel input 
  // https://github.com/jackocnr/intl-tel-input
  var input = document.querySelector(".phone-input");

  if (input !== null) {

    // Initialize
    var iti = window.intlTelInput(input, {
      initialCountry: "auto",
      dropdownContainer: document.body,
      geoIpLookup: function(success, failure) {
        $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
          var countryCode = (resp && resp.country) ? resp.country : "";          
          success(countryCode);
        });
      },
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.11/js/utils.js"
    }); 

    // Update width of of phone number input as type country code
    $('#country_code').on('keyup', function() {
      let len = $(this).val().length;
      if (len <= 1) {
        $('#phone_number').removeClass('pl-xl').removeClass('pl-l');
      } else if (len === 2) {
        $('#phone_number').removeClass('pl-xl').addClass('pl-l');
      } else {
        $('#phone_number').removeClass('pl-l').addClass('pl-xl');
      }
    })

    // Change country code input on selection of flag
    input.addEventListener("countrychange", function() {
      let countryData = iti.getSelectedCountryData();
      // Adjust padding of phone number field
      let len = countryData.dialCode.length;      
      if (len === 1) {
        $('#phone_number').removeClass('pl-xl').removeClass('pl-l');
      } else if (len === 2) {
        $('#phone_number').removeClass('pl-xl').addClass('pl-l');
      } else {
        $('#phone_number').removeClass('pl-l').addClass('pl-xl');
      }
      $('#country_code').val(countryData.dialCode);
    });

    // Validate input
    // https://intl-tel-input.com/node_modules/intl-tel-input/examples/gen/is-valid-number.html
    // Index maps to the error code returned from getValidationError - see readme
    var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
    var resetPhoneNumberErrors = function() { $('.phone-error-message').remove(); };

    // on blur: validate
    input.addEventListener('blur', function() {
      resetPhoneNumberErrors();
      if (input.value.trim()) {
        if (!iti.isValidNumber()) {

          // Get error code and update phone error message
          var errorCode = iti.getValidationError();
          $(input).after("<label class=\'phone-error-message\'>" + errorMap[errorCode] + "</label>");

        }
      }
    });

    // on keyup / change flag: resetPhoneNumberErrors
    input.addEventListener('change', resetPhoneNumberErrors);
    input.addEventListener('keyup', resetPhoneNumberErrors);

    // Format number as user types
    var formatAsType = function() {       
      let n = iti.getNumber(intlTelInputUtils.numberFormat.NATIONAL);
      $(input).val(n);
    };
    input.addEventListener('keyup', formatAsType);

  }

  // Move confirmation error to below confirmation field
  window.ClientSideValidations.callbacks.element.fail = function(element, message, callback) {
    callback();

    if (element.attr('id') === 'signup_form_password') {

      // Set message string
      let message = element.parent().find('.message').html();

      // Continue if message exists      
      if (typeof message !== 'undefined') {

        // If this is a password confirmation mismatch
        if (message == 'Password confirmation does not match') {
          
          // Hide the message above the password field
          element.parent().find('.message').hide();

          // Set password confirmation input
          let $confirmation = $('#signup_form_password_confirmation');

          // Set whether focused on password confirmation
          let $focused = $confirmation.is(":focus");

          // Check for focused and length; don't show error message if user has not yet entered a confirmation
          if ( ($confirmation.val().length !== 0) && (!$focused) ) {

            // Wrap confirmation input in error div, if not already wrapped
            // Otherwise unhide existing error div
            if (!$confirmation.closest('div').hasClass('field_with_errors')) {
              let errorDiv = '<div class="field_with_errors"></div>';
              let errorLabel = '<label for="signup_form_password_confirmation" class="message">Password confirmation does not match</label>';
              $('#signup_form_password_confirmation').wrap(errorDiv).after(errorLabel);
            } else {
              $('#signup_form_password_confirmation').parent().find('.message').show();
            }

          }

        } else {

          // If error is not a confirmation mismatch, unhide the error
          element.parent().find('.message').show();

        }
      }
    }
  }

  window.ClientSideValidations.callbacks.element.pass = function(element, callback) {
    // Take note how we're passing the callback to the hide()
    // method so it is run after the animation is complete.
    element.parent().find('.message').hide();

    if (element.attr('id') === 'signup_form_password') {

      // Set message string
      let message = element.parent().find('.message').html();

      if ((typeof message !== 'undefined') && (message == 'Password confirmation does not match')) {

        // Set confirmation input, remove error div
        let $confirmation = $('#signup_form_password_confirmation');
        $confirmation.parent().find('.message').hide();

      }

    }

  }


}