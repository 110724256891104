import Spinner from 'src/javascripts/components/utilities/Spinner';
import BuildApiUrl from 'src/javascripts/components/tables/url/BuildApiUrl';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';

export default class {

  constructor(el) { this.el = el; }

  getData() {

    // Set table element id
    const tableId = this.el.attr('id');

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url
    const url = BuildApiUrl(tableId);

    // Set url parameters for CORS requests to main Ethos site
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Get data
    return $.ajax({
      type: "GET",
      dataType: "application/json",
      beforeSend: function(request) {
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
        request.setRequestHeader("Session-Token", sessionToken);
        request.setRequestHeader("Email", userEmail);
      },
      url: url
    });

  }


  render() {}
    // handled in sub-classes

  setUp(tableId, chartId = null) {

    // Set on click action for table rows
    AddRowClickLinks(tableId);

    return 'setup';

  }
};