import introJs from 'intro.js/intro.js';
import CompaniesTypeahead from 'src/javascripts/components/typeaheads/CompaniesTypeahead';
import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';
import FundsTypeahead from 'src/javascripts/components/typeaheads/FundsTypeahead';
import CausesTypeahead from 'src/javascripts/components/typeaheads/CausesTypeahead';
import ListCarousels from 'src/javascripts/components/lists/ListCarousels';

export default class TeamsShow {

  constructor() {}

  render() {

    // Causes typeahead
    let causesTypeahead = new CausesTypeahead('#causes_search');
    $.when(causesTypeahead.initializeBloodhound('causes'))
     .then( bloodhound => causesTypeahead.render(bloodhound, 45, 'off', 0))
     .then( causesTypeahead.setUp('#causes_search'));

    // Companies typeahead
    let companiesTypeahead = new CompaniesTypeahead('#companies_search');
    companiesTypeahead.render();

    // Funds typeahead
    let fundsTypeahead = new FundsTypeahead('#funds_search');
    fundsTypeahead.render();

    // On click of a tab, focus on that typeahead
    $('#searchTeams .nav-link').click(function() {
      let tab = $(this).attr("href");
      setTimeout(function() {$(tab + ' .typeahead').focus()}, 300);
    })

    // List carousels
    ListCarousels();

    // Build tour
    let startIntroTour = function() {

      // Remove existing overlay
      $('.introjs-overlay').remove();

      // Temporarily hide all help circle tooltips on page
      $('.help-circle').addClass('d-none');

      // Temporarily disable modals
      $('.modal').on('show.bs.modal', function(e) { preventDefault(e); });

      // Set intro
      let intro = introJs();
      intro.setOptions({
        hidePrev: true,
        hideNext: true,
        showStepNumbers: false,
        scrollTo: 'tooltip',
        disableInteraction: true,
        overlayOpacity: 0.6,
        showBullets: false,
        doneLabel: 'OK',
        tooltipPosition: 'bottom'
      });

      // Define step 1 text
      let isTrial = $('#teamsQuickSearch').attr('data-trial');
      let onboardedStatus = $('#teamsQuickSearch').attr('data-onboarded');
      let step1;
      if (typeof isTrial !== 'undefined') {
        if (onboardedStatus === 'i') {
          step1 = "Your free trial has been restarted. You can complete the onboarding process any time under \'Get help\'.";
        } else {
          step1 = "Your free trial has been restarted. You can access the onboarding process any time under \'Get help\'.";
        }
      } else {
        if (onboardedStatus === 'i') {
          step1 = "You can complete the onboarding process any time under \'Get help\'.";
        } else {
          step1 = "You can access the onboarding process any time under \'Get help\'.";
        }
      }

      ToastCustom('Welcome!', step1, 8000);

      intro.onbeforeexit(function() { 
        
        // Reload page with Turbolinks on exit
        let teamUrl = $('body').attr('data-team-url');
        Turbolinks.visit('/' + teamUrl, { action: 'replace' });

      });

      // Start tour
      intro.start();

    }

    $(document).on("turbolinks:before-cache", function() { 

      // Exit tour before navigate away with Turbolinks
      // Doesn't work on back button if we don't do this
      introJs().exit();

      // Hide navbar dropdowns, otherwise reappears 
      // for a second when Turbolinks reloads the page
      $('.top-nav-dropdown').removeClass('show');

    });

    // Show welcome tour
    let onboarded = typeof $('#teamsQuickSearch').attr('data-onboarded') !== 'undefined';
    if (onboarded) { 
      setTimeout(function() {
        startIntroTour(); 
      }, 100);
    }

  }

}