import PortfolioModelsTable from 'src/javascripts/components/tables/PortfolioModelsTable';
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default class TeamsPortfoliosModelsIndex {

  constructor() {}

  render() {

    // Render top table
    if ( $('#portfolio_models_table_wrapper').length === 0 ) {
      let table = new PortfolioModelsTable($('#portfolio_models_table'));
      $.when( table.render() ).then( status => table.setUp('portfolio_models_table') )
    }

    // Update table on move to or from full height
    $('.toggle-full-view.models-full-view').click(function() {
      let $card = $(this).closest('.card');
      Spinner($card);
      $card.find('.card-body').css('opacity', 0.1);
      setTimeout(function() {
        let $table = $('#portfolio_models_table').DataTable();
        $table.clear().destroy();
        $table = new PortfolioModelsTable($('#portfolio_models_table'));
        $.when( $table.render() ).then( status => $table.setUp('portfolio_models_table') )
      }, 200);
    });


  }

}