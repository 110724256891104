import PortfolioItemsTable from 'src/javascripts/components/tables/PortfolioItemsTable';

export default class TeamsPortfoliosTemperatureIndex {

  constructor() {}

  render() {


    // Portfolio items, if present on page
    if ($('.portfolio-items-table').length > 0) {
      $('.portfolio-items-table').each(function() {
        let tableId = $(this).attr('id');
        let portfolioItemsTable = new PortfolioItemsTable($('#' + tableId));
        $.when( portfolioItemsTable.render(tableId) ).then( status => portfolioItemsTable.setUp(tableId) );
      })
    }

  }

}