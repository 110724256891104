import Spinner from 'src/javascripts/components/utilities/Spinner';

export default function() {

  // Set a spinner to indicate chart loading
  Spinner('#portfolioCarbonFootprintBody');
  $('#portfolioCarbonFootprintChart').css('opacity', 0.25);

  // Set url
  let dataUrl = $('#portfolioCarbonFootprintBody').attr('data-url');

  // Set url parameters for CORS requests to main Ethos site
  let sessionToken = $('body').attr('data-session-token');
  let userEmail = $('body').attr('data-email');
  let ethosCorsKey = $('body').attr('data-ethos-cors-key');
  let mainEthosUrl = $('body').attr('data-main-ethos-url');

  // Set empty hash to build params
  let params = {};

  // Set other filters from loadMore button
  let token = $('#portfolioCarbonFootprintBody').attr("data-token");
  let reportId = $('#portfolioCarbonFootprintBody').attr("data-report-id");
  let backPath = $('#portfolioCarbonFootprintBody').attr("data-back-path");
  if (typeof token !== 'undefined') {params["token"] = token;}
  if (typeof backPath !== 'undefined') {params["back_path"] = backPath;}
  if (typeof reportId !== 'undefined') {params["report_id"] = reportId;}

  // Execute ajax request (using js erb template to render content so can control profile styling more easily)
  // Must specify '.js' otherwise processes as JSON
  return $.ajax({
    type: "GET",
    dataType: "script",
    beforeSend: function(request) {
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
      request.setRequestHeader("Session-Token", sessionToken);
      request.setRequestHeader("Email", userEmail);
    },
    url: dataUrl + '.js?' + $.param(params)
  });

}
