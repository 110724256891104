import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class PortfolioControversiesTableFund extends Table {

  render(tableId) {

    // Additional detail info
    function formatAddlInfo (row) {
      let details = $(row).attr('data-details');
      let year = $(row).attr('data-year');
      let container = '<div class=\'extra-info-container\'>';
      if (typeof details !== 'undefined') {
        container += '<div class=\'my-2\'>' + details + '</div>';
      }
      container += '<div>Year: ' + year + '</div>';
      container += '</div>';
      return container;
    }

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Set cols
    const cols = GetCols(this.el);

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Render table
    const table = $('#' + tableId).DataTable({
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      pageLength: 10,
      scrollx: true,
      dom: 'tp',
      columns: cols,      
      ordering: true,
      order: [[2, 'asc']],
      language: { 
        emptyTable: "No controversies information available for this fund. Please contact us with any questions.",
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        $(row).attr('data-details', data.details);
        $(row).attr('data-year', data.year);

      },

      drawCallback: function( settings ) {

        // Hide paging if total display is equal to or less than page length
        if ($('#' + tableId).attr('data-tot-records') < 11) {
          $('#' + tableId + '_paginate').addClass('d-none');
        }

        $('#' + tableId + ' tbody').off('click').on('click', 'tr:not(.expanded-row) td', function () {

          // Set this tr
          let tr = $(this).closest('tr');

          // Remove currently shown row, if any 
          if (tr.hasClass('expanded')) {
            tr.removeClass('expanded');
            $('.extra-info-container').empty();  
            tr.find('svg.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-right');

          } else {
            $('tr.expanded').find('svg.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-right');
            $('tr.expanded').removeClass('expanded');
            $('.extra-info-container').empty();            
            tr.addClass('expanded');

            // Append to tr
            tr.find('.extra-info-container').replaceWith( formatAddlInfo(tr) );
            tr.find('svg.fa-chevron-right').removeClass('fa-chevron-right').addClass('fa-chevron-down');
          }

        });

        // Re-init tooltips for lock icons
        $('[data-toggle=tooltip]').tooltip();

      },

    });

    return 'rendered';

  }
};