import Table from 'src/javascripts/components/Table';

export default class PortfolioControversiesTable extends Table {

  render(tableId) {

    // Additional detail info
    function formatAddlInfo (row) {
      let details = $(row).attr('data-details');
      let year = $(row).attr('data-year');
      let container = '<div class=\'extra-info-container\'>';
      if (typeof details !== 'undefined') {
        container += '<div class=\'my-2\'>' + details + '</div>';
      }
      container += '<div>Year: ' + year + '</div>';
      container += '</div>';
      return container;
    }

    // Render table
    const table = $('#' + tableId).DataTable({
      pageLength: 10,
      scrollx: true,
      dom: 'tp',
      ordering: true,
      order: [[2, 'asc']],
      drawCallback: function( settings ) {

        // Hide paging if total display is equal to or less than page length
        if ($('#' + tableId).attr('data-tot-records') < 11) {
          $('#' + tableId + '_paginate').addClass('d-none');
        }

        $('#' + tableId + ' tbody').off('click').on('click', 'tr:not(.expanded-row) td', function () {

          // Set this tr
          let tr = $(this).closest('tr');

          // Remove currently shown row, if any 
          if (tr.hasClass('expanded')) {
            tr.removeClass('expanded');
            $('.extra-info-container').empty();  
            tr.find('svg.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-right');

          } else {
            $('tr.expanded').find('svg.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-right');
            $('tr.expanded').removeClass('expanded');
            $('.extra-info-container').empty();            
            tr.addClass('expanded');

            // Append to tr
            tr.find('.extra-info-container').replaceWith( formatAddlInfo(tr) );
            tr.find('svg.fa-chevron-right').removeClass('fa-chevron-right').addClass('fa-chevron-down');
          }

        });

        // Re-init tooltips for lock icons
        $('[data-toggle=tooltip]').tooltip();

      },

    });

    return 'rendered';

  }
};