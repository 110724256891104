import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class SettingsTeamsEdit {

  constructor() {}

  render() {

    // Change color preview on color input change
    const UpdateColorPrev = function(input) {
    	let color = input.val();
    	let prev = input.closest('.input-group').find('.input-group-append .input-group-text');
    	prev.css('background-color', color);
    }

    $('.color-group input').change(function() {
    	UpdateColorPrev($(this));
    });

    // Reset default colors on click
    $('.reset-default-colors').click(function() {
    	let color = $(this).attr('data-color');
    	let inverse = $(this).attr('data-inverse');
    	let g = $(this).closest('.color-group');
    	let cI = g.find('.color-input');
    	let iI = g.find('.inverse-input');
    	cI.val(color);
    	UpdateColorPrev(cI);
    	iI.val(inverse);
    	UpdateColorPrev(iI);
    });

  }

}