// Import JS used on multiple pages
import ToastFlash from 'src/javascripts/components/alerts/ToastFlash';
import FocusSearchForm from 'src/javascripts/components/forms/FocusSearchForm';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import Collapsible from 'src/javascripts/components/utilities/Collapsible';
import TooltipInit from 'src/javascripts/components/utilities/TooltipInit';
import NavLanding from 'src/javascripts/components/nav/NavLanding';
import NavbarNotifications from 'src/javascripts/components/notifications/NavbarNotifications';
import Sidebar from 'src/javascripts/components/nav/Sidebar';
import SocialShare from 'src/javascripts/components/SocialShare';
import ImageReposition from 'src/javascripts/components/utilities/ImageReposition';
import MasterSearchTypeahead from 'src/javascripts/components/typeaheads/MasterSearchTypeahead';

// Help center pages
import Landing from 'src/javascripts/pages/Landing';

// Landing pages
import LandingMy from 'src/javascripts/pages/landing/LandingMy';

// Help center pages
import Help from 'src/javascripts/pages/Help';

// Auth pages
import Auth from 'src/javascripts/pages/Auth';

// Teams pages
import TeamsShow from 'src/javascripts/pages/teams/TeamsShow';
import TeamsPortfoliosNew from 'src/javascripts/pages/teams/portfolios/TeamsPortfoliosNew';
import TeamsPortfoliosEdit from 'src/javascripts/pages/teams/portfolios/TeamsPortfoliosEdit';
import TeamsPortfoliosShow from 'src/javascripts/pages/teams/portfolios/TeamsPortfoliosShow';
import TeamsPortfoliosMetricsShow from 'src/javascripts/pages/teams/portfolios/metrics/TeamsPortfoliosMetricsShow';
import TeamsPortfoliosMetricScoresCarbonFootprintDetails from 'src/javascripts/pages/teams/portfolios/metric_scores/TeamsPortfoliosMetricScoresCarbonFootprintDetails';
import TeamsPortfoliosModelsIndex from 'src/javascripts/pages/teams/portfolios/models/TeamsPortfoliosModelsIndex';
import TeamsPortfoliosModelsEdit from 'src/javascripts/pages/teams/portfolios/models/TeamsPortfoliosModelsEdit';
import TeamsPortfoliosModelsImportsNew from 'src/javascripts/pages/teams/portfolios/models/TeamsPortfoliosModelsImportsNew';
import TeamsPortfoliosModelsImportsMap from 'src/javascripts/pages/teams/portfolios/models/TeamsPortfoliosModelsImportsMap';
import TeamsPortfoliosModelsBulkImportsNew from 'src/javascripts/pages/teams/portfolios/models/TeamsPortfoliosModelsBulkImportsNew';
import TeamsPortfoliosModelsBulkImportsMap from 'src/javascripts/pages/teams/portfolios/models/TeamsPortfoliosModelsBulkImportsMap';
import TeamsPortfoliosControversiesIndex from 'src/javascripts/pages/teams/portfolios/controversies/TeamsPortfoliosControversiesIndex';
import TeamsPortfoliosTemperatureIndex from 'src/javascripts/pages/teams/portfolios/temperature/TeamsPortfoliosTemperatureIndex';
import TeamsPortfoliosAdvocacyIndex from 'src/javascripts/pages/teams/portfolios/advocacy/TeamsPortfoliosAdvocacyIndex';
import TeamsPortfoliosScreensIndex from 'src/javascripts/pages/teams/portfolios/screens/TeamsPortfoliosScreensIndex';
import TeamsUpdateSummariesShow from 'src/javascripts/pages/teams/update_summaries/TeamsUpdateSummariesShow';

// Settings
import SettingsTeamsEdit from 'src/javascripts/pages/settings/teams/SettingsTeamsEdit';
import SettingsTeamsImagesIndex from 'src/javascripts/pages/settings/teams/images/SettingsTeamsImagesIndex';
import SettingsTeamsMembershipsIndex from 'src/javascripts/pages/settings/teams/memberships/SettingsTeamsMembershipsIndex';

// Notifications
import NotificationsIndex from 'src/javascripts/pages/notifications/NotificationsIndex';
import NotificationsSettingsIndex from 'src/javascripts/pages/notifications/settings/NotificationsSettingsIndex';

// Create a map where keys share same names as classes
const pageClasses = {
	LandingMy, TeamsShow, TeamsPortfoliosShow, TeamsPortfoliosEdit, TeamsPortfoliosMetricsShow, 
  TeamsPortfoliosModelsIndex, TeamsPortfoliosModelsImportsNew,
  TeamsPortfoliosModelsImportsMap, TeamsPortfoliosNew, SettingsTeamsImagesIndex,
  NotificationsIndex, NotificationsSettingsIndex, SettingsTeamsEdit, SettingsTeamsMembershipsIndex,
  TeamsPortfoliosControversiesIndex, TeamsPortfoliosScreensIndex, TeamsPortfoliosTemperatureIndex, 
  TeamsPortfoliosModelsEdit, TeamsPortfoliosAdvocacyIndex, TeamsPortfoliosMetricScoresCarbonFootprintDetails, 
  TeamsUpdateSummariesShow, TeamsPortfoliosModelsBulkImportsNew,
  TeamsPortfoliosModelsBulkImportsMap
}

// Create a map for module-wide classes
const moduleClasses = {Help, Auth, Landing}

// Set vars for listening for reloading page, maintaining scroll position if on same page
// https://stackoverflow.com/questions/39679154/how-to-persist-scrolls-with-turbolinks
const scrollPositions = {}
let page = $('body').data('page');
scrollPositions[page] = null

// Init page
const Init = function() {

  // Add lastpass and chrome ignore indicators
  if ($('body').attr('data-signed-in') === 'true') {
    $('input').attr('data-lp-ignore', 'true');
  }
  $('input').attr('autocomplete', 'off');

  // Show flash toast, if present on page
  if ($('.flash').length !== 0) { ToastFlash(); }

  // Add border to navbar landing, if on a landing page
  if ($('.nav-landing').length !== 0) { NavLanding(); }

  // Top navbar if signed in
  if ($('#navTop').length !== 0) {NavbarNotifications();}

  // Add social share if present
  if ($('.social-share-button').length !== 0) {SocialShare();}

  // Collapse sidebar if present
  if ($('.minimize-sidebar').length !== 0) {Sidebar()};

  // Toggle focus for typeahead on click of search button
  // Used in multiple nav bars
  if ($('.focus-search-form').length !== 0) {
    $('.focus-search-form').click(function() { FocusSearchForm($(this)); });
  }

  // Alert dismissal
  if ($('.app-alert').length !== 0) {
    $('header nav.navbar').css('height', '80px');
    $('.app-alert .close, .app-alert a').click(function() {
      $('.app-alert').remove();
      $('header nav.navbar').css('height', '70px');
      $.ajax({
        type: "PATCH",
        dataType: "application/json",
        url: '/notifications/dismiss'
      });

    });
  }

  // Toggle full-window view for cards
  if ($('.toggle-full-view').length !== 0) {
    $('.toggle-full-view').click(function() {
      let $card = $(this).closest('.card')
      let $chart = $card.find('.chart').data('c3-chart');
      $card.toggleClass('full-view');
      if ( typeof $chart !== 'undefined' ) {
        setTimeout(function() {
          $chart.flush();
        }, 100);
      }
    });
  }

  // Multi-search causes, companies, funds
  if ( $('#master_search_typeahead') ) {
    let masterSearch = new MasterSearchTypeahead('master_search_typeahead');
    $.when( masterSearch.render() );
  }

  // Reposition images if repositionable image present
  if ($('.repositionable').length !== 0) {
    ImageReposition();
  }

  // Scroll to anchor on click
  if ($('.scroll-to-anchor').length !== 0) {
    $('.scroll-to-anchor').click(function(e) {
      e.preventDefault();
      let tag = $(this).attr('data-tag');
      return ScrollToTag(tag);
    });
  }

  // Set collapsible
  if ($('.collapse-item').length !== 0) {
    Collapsible();
  }

  // Init tooltips
  TooltipInit();

  // Remove toasts once they're hidden, so they don't show up on page change
  $('.toast').on('hidden.bs.toast', function () {
    $(this).remove();
  });

  // Auto-show modals
  if ($('.modal.auto-show').length !== 0) {

    // Show modal if present on page (if user signed in)
    $('.modal.auto-show').on('shown.bs.modal', function() {

      // get the top of the section
      let p = $(this).find('.modal-body').attr("data-scroll-p");

      // Scroll to section if p present
      if (typeof p !== 'undefined') { $(this).find('.modal-body').scrollTop(p); }

    }).modal({backdrop: 'static', keyboard: false});

  }

  // Set page, parent and should scroll attrs from body
  let page = $('body').data('page');
  let module = $('body').data('controller-module');
  let shouldScroll = $('body').data('should-scroll');

  // Load at scroll position, if body indicates we should
  if ( (typeof shouldScroll !== 'undefined') && (scrollPositions[page]) ) {
    window.scrollTo(0, scrollPositions[page]);
  }

	// Execute page class, if exists
	if (typeof pageClasses[page] !== 'undefined') {
		new pageClasses[page]().render();
	}

  // Execute module class, if exists
  if (typeof moduleClasses[module] !== 'undefined') {
    new moduleClasses[module]().render();
  }

}


// Call init function on page load
// Remove typeaheads, charts and datatables before cache
export default $(document).on("turbolinks:load", function() { 

	Init(); 

}).on("turbolinks:before-visit", function() {

  // Add listeners for reloading page, maintaining scroll position if on same page
  // https://stackoverflow.com/questions/39679154/how-to-persist-scrolls-with-turbolinks
  let page = $('body').data('page');
  scrollPositions[page] = window.scrollY;

}).on("turbolinks:before-cache", function() {

  // Collapse master search if present
  if ($('#master_search_typeahead').length !== 0) {
    $('#navbarSearchSm').removeClass('show');
    $('#master_search_typeahead').val('');
  }

  // Destroy typeaheads to avoid dup rendering on back button
  $('.typeahead').typeahead('destroy');

  // Empty legends from below charts
  if ($('.legend').length !== 0) { $('.legend').empty(); }

  // Remove google maps from BOM (window object)
  if (window.google !== undefined && window.google.maps !== undefined) {
    window.google.maps = undefined;
  }

  // Destroy datatables
  $('.dataTable.server-side tbody').remove(); // Avoid flickering/jumping on back button for tables that get data from server
  $('.dataTable').DataTable().destroy();

  // Remove modal backdrop; if don't remove before cache it appears twice on back button
  $('.modal-backdrop').remove();

  // Hide modals
  $('.modal').modal('hide');

  // Hide tooltips if not on mobile/touch
  let isTouchDevice = function() { 
    return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
  }
  if (isTouchDevice() === false) {
    $('[data-toggle="tooltip"]').tooltip('hide');
  }


});