import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';

export default class PortfolioItemsUnderlyingTable extends Table {

  render() {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Set table id
    const tableId = this.el.attr('id');

    // Build url, use to get filtered data with server-side processing
    let dataUrl = this.el.attr('data-api-url');

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);
    let colLen = cols.length;

    // Additional detail info
    // 'd' is the original data object for the row
    function formatAddlInfo (d) {
      let container = '<div class=\'extra-info-container\'>';
      if (parseFloat(d.weight_stock) !== 0) {
        container += '<div class=\'font-weight-normal metric-info mb-2 mr-2 d-flex\'>' + d.name + ' (held stock):<span class=\'ml-auto\'>' + d.weight_stock + '</span></div>';
      }

      $.each(d.stock_funds, function(index, sf) {
        if (index === d.stock_funds.length - 1) {
          container += '<div class=\'font-weight-normal metric-info border-bottom mb-2 mr-2 d-flex\'>' + sf.name + ' (fund holding):<span class=\'ml-auto\'>' + sf.weight + '</span></div>';
        } else {
          container += '<div class=\'font-weight-normal metric-info mb-2 mr-2 d-flex\'>' + sf.name + ' (fund holding):<span class=\'ml-auto\'>' + sf.weight + '</span></div>';
        }
      });
      container += '<div class=\'font-weight-bold metric-info mb-2 mr-2 d-flex\'>Total:<span class=\'ml-auto font-weight-bold\'>' + d.weight_total + '</span></div>';
      container += '</div>';
      return container;
    }

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
      lengthChange: true,
      searching: true,
      order: [[1, 'desc']],
      columns: cols,
      dom: 'tp',
      responsive: { details: false },
      language: { 
        search: "_INPUT_",
        searchPlaceholder: "Search...",
        lengthMenu: "Show _MENU_",
        infoEmpty: "No stocks found. Add one or reach out to us for support.",
        emptyTable: "No stocks found. Add one or reach out to us for support.",
        zeroRecords: "No stocks found. Add one or reach out to us for support."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).find('.spinner-container').remove();

        // If no data yet 
        if (settings.json.recordsTotal === 0) {
          $('#portfolio_items_underlying_table').addClass('d-none');
          $('#portfolio_items_underlying_table_paginate').addClass('d-none');
        } else {
          $('#portfolio_items_underlying_table').removeClass('d-none');
          $('#portfolio_items_underlying_table_paginate').removeClass('d-none');
        }

        $('#' + tableId + ' tbody').off('click').on('click', 'tr:not(.expanded-row) td', function () {

          // Set this tr and DataTable row
          let tr = $(this).closest('tr');
          let row = table.row(tr);

          // Hide other currently shown row, if any 
          let currentTr = $('#' + tableId + ' tbody tr.shown').not(tr);
          let currentRow = table.row(currentTr);
          if (typeof currentRow !== 'undefined') {
            currentRow.child.hide();
            currentTr.removeClass('shown');
            currentTr.find('.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
          }

          // Open/hide this row
          if ( row.child.isShown() ) {
            row.child.hide();
            $('.extra-info-container').closest('tr').removeClass('expanded-row');
            tr.removeClass('shown');
            tr.find('.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');

          } else {
            row.child( formatAddlInfo(row.data()) ).show();
            $('.extra-info-container').closest('tr').addClass('expanded-row');
            tr.addClass('shown');
            tr.find('.fa-chevron-down').removeClass('fa-chevron-down').addClass('fa-chevron-up');
          }

        });


      }


    });

    return 'rendered';
  }

  setUp(tableId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    return 'setup';
  }


}
