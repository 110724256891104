// Generate company or fund ratings cards
import Spinner from 'src/javascripts/components/utilities/Spinner';

export default function() {

  // Set Api url to get data
  let dataUrl = $('.filter-data').attr('data-api-url');

  // Set CORS variables
  let sessionToken = $('body').attr('data-session-token');
  let userEmail = $('body').attr('data-email');
  let ethosCorsKey = $('body').attr('data-ethos-cors-key');
  let mainEthosUrl = $('body').attr('data-main-ethos-url');

  // Set empty hash to build filters
  let filters = {};

  // Set record count to load
  filters['start'] = $('.filter-data').attr('data-api-offset-count');
  filters['length'] = $('.filter-data').attr('data-api-record-count');

  // Set updated formula id from formula_filter
  let formula_id = $('#formula_filter').attr('data-api-formula-id');

  // Set other filters from loadMore button
  let reportId = $('#loadMore').attr("data-report-id");
  let benchmarkType = $('#loadMore').attr("data-benchmark-type");
  let benchmarkId = $('#loadMore').attr("data-benchmark-id");
  let sector = $('#loadMore').attr("data-sector");
  let screens = $('#loadMore').attr("data-screens");
  let industry = $('#loadMore').attr("data-industry");
  let peer_group = $('#loadMore').attr("data-peer-group");
  let hq_country = $('#loadMore').attr("data-hq-country");
  let hq_state = $('#loadMore').attr("data-hq-state");
  let hq_city = $('#loadMore').attr("data-hq-city");
  let market_cap = $('#loadMore').attr("data-market-cap");
  let symbol = $('#loadMore').attr("data-symbol");
  let cause_id = $('#loadMore').attr("data-cause-id");
  let metric_id = $('#loadMore').attr("data-metric-id");
  let client_id = $('#loadMore').attr("data-client-id");
  let token = $('#loadMore').attr("data-token");
  let backPath = $('#loadMore').attr("data-back-path");
  // let formula_id = $('#loadMore').attr("data-formula-id");
  let record_type = $('#loadMore').attr("data-record-type");
  if (typeof reportId !== 'undefined') {filters["report_id"] = reportId;}
  if (typeof benchmarkType !== 'undefined') {filters["benchmark_type"] = benchmarkType;}
  if (typeof benchmarkId !== 'undefined') {filters["benchmark_id"] = benchmarkId;}
  if (typeof sector !== 'undefined') {filters["sector"] = JSON.parse(sector);}
  if (typeof screens !== 'undefined') {filters["screens"] = JSON.parse(screens);}
  if (typeof industry !== 'undefined') {filters["industry"] = JSON.parse(industry);}
  if (typeof peer_group !== 'undefined') {filters["peer_group"] = JSON.parse(peer_group);}
  if (typeof hq_country !== 'undefined') {filters["hq_country"] = JSON.parse(hq_country);}
  if (typeof hq_state !== 'undefined') {filters["hq_state"] = JSON.parse(hq_state);}
  if (typeof hq_city !== 'undefined') {filters["hq_city"] = JSON.parse(hq_city);}
  if (typeof market_cap !== 'undefined') {filters["market_cap"] = JSON.parse(market_cap);}
  if (typeof symbol !== 'undefined') {filters["symbol"] = JSON.parse(symbol);}
  if (typeof record_type !== 'undefined') {filters["record_type"] = record_type;}
  if (typeof token !== 'undefined') {filters["token"] = token;}
  if (typeof backPath !== 'undefined') {filters["back_path"] = backPath;}
  if (typeof client_id !== 'undefined') {
    filters["client_id"] = JSON.parse(client_id);
  } else if (typeof cause_id !== 'undefined') {
    filters["cause_id"] = JSON.parse(cause_id);
  } else if (typeof metric_id !== 'undefined') {
    filters["metric_id"] = JSON.parse(metric_id);
  } else if (typeof formula_id !== 'undefined') {
    filters["formula_id"] = JSON.parse(formula_id);
  }

  // Execute ajax request (using js erb template to render content so can control profile styling more easily)
  // Must specify '.js' otherwise processes as JSON
  return $.ajax({
    type: "GET",
    dataType: "script",
    beforeSend: function(request) {
      request.setRequestHeader("Content-Type", "application/json");
      request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
      request.setRequestHeader("Session-Token", sessionToken);
      request.setRequestHeader("Email", userEmail);
    },
    url: dataUrl + '.js?' + $.param(filters)
  });

}