import PortfolioScreensTableFund from 'src/javascripts/components/tables/PortfolioScreensTableFund';

export default class TeamsPortfoliosScreensIndex {

  constructor() {}

  render() {


    // Expand holding rows on click
    $('.screen-title').click(function() {
      let fc = $(this).closest('.holding-screen-details');
      $('.holding-screen-details').not(fc).removeClass('expanded');
      fc.toggleClass('expanded');
      fc.find('.screen-title svg').toggleClass('fa-chevron-right').toggleClass('fa-chevron-down');

      if (fc.hasClass('expanded')) {
        fc.find('.more-info').empty().append("(click to hide)");
        let t = fc.find('.portfolio-screens-table-fund');
        let tableId = t.attr('id');

        if ( $('#' + tableId + '_wrapper').length === 0 ) {
          let table = new PortfolioScreensTableFund($('#' + tableId));
          $.when( table.render(tableId) )
        }

      } else {
        fc.find('.more-info').empty().append("(click for more info)");
      }
    })

  }

}