import Spinner from 'src/javascripts/components/utilities/Spinner';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import ExpandContent from 'src/javascripts/components/utilities/ExpandContent';
import ExpandExcerpt from 'src/javascripts/components/utilities/ExpandExcerpt';
import PortfolioMetricScoresTable from 'src/javascripts/components/tables/PortfolioMetricScoresTable';
import PortfolioItemsTable from 'src/javascripts/components/tables/PortfolioItemsTable';
import PortfolioItemsUnderlyingTable from 'src/javascripts/components/tables/PortfolioItemsUnderlyingTable';
import ImpactDistPortfolio from 'src/javascripts/components/charts/ImpactDistPortfolio';
import ImpactDistMetricScores from 'src/javascripts/components/charts/ImpactDistMetricScores';
import ToggleReturnsChart from 'src/javascripts/components/charts/actions/ToggleReturnsChart';
import ReturnsDollarsChart from 'src/javascripts/components/charts/ReturnsDollarsChart';
import ReturnsChart from 'src/javascripts/components/charts/ReturnsChart';
import ReturnsTable from 'src/javascripts/components/tables/ReturnsTable';
import SdgsTable from 'src/javascripts/components/tables/SdgsTable';
import PortfolioCausesTable from 'src/javascripts/components/tables/PortfolioCausesTable';
import CardList from 'src/javascripts/components/CardList';
import CarbonComparison from 'src/javascripts/components/charts/CarbonComparison';
import CarbonFootprint from 'src/javascripts/components/charts/CarbonFootprint';
import PortfolioStocksPoll from 'src/javascripts/components/polling/PortfolioStocksPoll';
import PortfolioScreensPoll from 'src/javascripts/components/polling/PortfolioScreensPoll';

export default class TeamsPortfoliosShow {

  constructor() {}

  render() {

    // Expand/minimize profile details
    ExpandContent();

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Expand when click on table of contents item
    $('.sticky-sidebar a.scroll-to-section').click(function(e) {
      e.preventDefault();
      let sectionId = $(this).attr('data-tag');
      let toExpand = $('#' + sectionId).find('.collapse');
      toExpand.collapse('show');
      $('.collapse').not(toExpand).collapse('hide');
      setTimeout(function() {
        ScrollToTag(sectionId);  
      }, 500);
    });

    // Expand when click on table of contents item
    $(window).scroll(function(e) {
      let scroll = $(window).scrollTop();
      if (scroll > 130) {
        $('#sidebarTitleInfo').removeClass('min-height');
      } else {
        $('#sidebarTitleInfo').addClass('min-height');
      }
    });
    let scroll = $(window).scrollTop();
    if (scroll > 130) {
      $('#sidebarTitleInfo').removeClass('min-height');
    } else {
      $('#sidebarTitleInfo').addClass('min-height');
    }

    // Portfolio assessment visuals, if present on page
    if ($('.assessment-vis').length > 0) {

      $('.assessment-vis').each(function() {
        let vid = $(this).attr('id');

        Spinner($('#' + vid));

        // Set params for loading metrics
        let teamUrl = $('#' + vid).attr('data-team-url');
        let portfolioId = $('#' + vid).attr('data-portfolio-id');
        let impactApproach = $('#' + vid).attr('data-impact-approach');
        let advocacy = $('#' + vid).attr('data-advocacy');
        let backPath = $('#' + vid).attr('data-back-path');
        let url = '/' + teamUrl + '/portfolios/assessment_vis';
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['back_path'] = backPath;
        params['features'] = 'false';
        params['impact_approach'] = impactApproach;
        params['advocacy'] = advocacy;

        // Load assessment visuals
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js?' + $.param(params)
        });

        // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
        $(document).on("turbolinks:before-cache", function() {
          $('#' + vid).empty();
        })

      })        

    } 

    $('.card-list-items.collapse').on('show.bs.collapse', function () {
      // Set section
      let $section = $(this).closest('.card-list');
      // Toggle collapse item on 
      $section.find('.toggleable').toggleClass('d-none');
      // Toggle section background
      $section.addClass('active');
      // Reflow chart if present
      let $chart = $(this).find('.chart').data('c3-chart');
      if ( typeof $chart !== 'undefined' ) {
        setTimeout(function() {
          $chart.flush();
        }, 100);
      }
    });


    $('.card-list-items.collapse').on('hide.bs.collapse', function () {
      // Set section
      let $section = $(this).closest('.card-list');
      // Toggle collapse item on 
      $section.find('.toggleable').toggleClass('d-none')
      // Toggle section background
      $section.removeClass('active');
    });

    // Portfolio sectors
    let portfolioId = $('#sectorsTab').attr('data-portfolio-id');
    let teamUrl = $('body').attr('data-team-url');
    if (typeof portfolioId !== 'undefined') {
      $.ajax({
        type: "GET",
        dataType: "script",
        url: '/' + teamUrl + '/portfolios/' + portfolioId + '/characteristics.js?scope=sectors'
      });
    }

    // Characteristics on tab shown
    $('#portfolioCharacteristicsCharts a[data-toggle=\'tab\']').on('shown.bs.tab', function (e) {
      Spinner($('#charTabContent'));
      let scope = $(this).attr('data-scope');
      let portfolioId = $('#sectorsTab').attr('data-portfolio-id');
      let teamUrl = $('body').attr('data-team-url');
      $.ajax({
        type: "GET",
        dataType: "script",
        url: '/' + teamUrl + '/portfolios/' + portfolioId + '/characteristics.js?scope=' + scope
      });      
    });

    // Get fund stories
    if ($('#portfolioFundImpactStoriesGraphic').length > 0) {
      Spinner($('#portfolioFundImpactStoriesGraphic'));
      let teamUrl = $('body').attr('data-team-url');
      $.ajax({
        type: "GET",
        dataType: "script",
        url: '/' + teamUrl + '/portfolios/' + portfolioId + '/fund_stories.js'
      });
    }

    // Portfolio metric scores distribution
    if (typeof $('#impact_dist_metric_scores').data('c3-chart') === 'undefined') {
      if ($('#impact_dist_metric_scores').length > 0) {
        let chart = new ImpactDistMetricScores( $('#impact_dist_metric_scores') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }
    }

    // Portfolio metric scores, if present on page
    if ($('.metric-scores-table').length > 0) {
      $('.metric-scores-table').each(function() {
        let tableId = $(this).attr('id');
        let portfolioMetricScoresTable = new PortfolioMetricScoresTable($('#' + tableId));
        $.when( portfolioMetricScoresTable.render(tableId) ).then( status => portfolioMetricScoresTable.setUp(tableId) );
      })
    }

    // Portfolio carbon metrics
    if ($('#portfolioCarbonComparisonMetrics').length > 0) { CarbonComparison(); }

    // Portfolio carbon footprint calculation
    if ($('#portfolioCarbonFootprintChart').length > 0) { CarbonFootprint(); }

    // Impact dist chart, if chart does not already have c3-data (already initialized)
    if (typeof $('#impact_dist_portfolio').data('c3-chart') === 'undefined') {
      if ($('#impact_dist_portfolio').length > 0) {
        let chart = new ImpactDistPortfolio( $('#impact_dist_portfolio') );
        $.when( chart.getData() )
         .then( data => chart.render(data) );
      }
    }

    // Portfolio items, if present on page
    if ($('.portfolio-items-table').length > 0) {
      $('.portfolio-items-table').each(function() {
        let tableId = $(this).attr('id');
        let portfolioItemsTable = new PortfolioItemsTable($('#' + tableId));
        $.when( portfolioItemsTable.render(tableId) ).then( status => portfolioItemsTable.setUp(tableId) );
      })
    }

    // Portfolio items underlying stocks, if present on page
    if ($('#portfolio_items_underlying_table').length > 0) {
      let tableId = 'portfolio_items_underlying_table';
      let portfolioItemsUnderlyingTable = new PortfolioItemsUnderlyingTable($('#' + tableId));
      $.when( portfolioItemsUnderlyingTable.render(tableId) ).then( status => portfolioItemsUnderlyingTable.setUp(tableId) );
    } 

    // Portfolio stocks and screens polling
    PortfolioStocksPoll();
    PortfolioScreensPoll();

    // Initialize a new chart instance for each chart on the page
    if (($('#returns_chart').attr('data-return-option') === 'dollar') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

      // Set chartId and chart object
      // Get data, render chart, set up chart, and create connected table, if exists
      let chart = new ReturnsDollarsChart($('#returns_chart'));
      $.when(chart.getData())
       .then( data => chart.render(data))
       .then( chartObj => chart.setUp(chartObj));

    } else if (($('#returns_chart').attr('data-return-option') === 'percentage') && (typeof $('#returns_chart').data('c3-chart') === 'undefined')) {

      // Set chartId and chart object
      // Get data, render chart, set up chart, and create connected table, if exists
      let chart = new ReturnsChart($('#returns_chart'));
      $.when(chart.getData())
       .then( data => chart.render(data))
       .then( chartObj => chart.setUp(chartObj));

    }

    // Toggle returns chart between hypothetical growth and percentage returns
    ToggleReturnsChart();

    // Portfolio items return attrs, if present on page
    if ($('#returns_table').length > 0) {
      let returnsTable = new ReturnsTable($('#returns_table'));
      $.when( returnsTable.render('returns_table') ).then( status => returnsTable.setUp('returns_table') );
    }

    // Portfolio SDGs visuals, if present on page
    if ($('.assessment-sdgs').length > 0) {

      $('.assessment-sdgs').each(function() {
        let vid = $(this).attr('id');

        Spinner($('#' + vid));

        // Set params for loading metrics
        let teamUrl = $('#' + vid).attr('data-team-url');
        let portfolioId = $('#' + vid).attr('data-portfolio-id');
        let impactApproach = $('#' + vid).attr('data-impact-approach');
        let advocacy = $('#' + vid).attr('data-advocacy');
        let backPath = $('#' + vid).attr('data-back-path');
        let url = '/' + teamUrl + '/portfolios/assessment_sdgs';
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['back_path'] = backPath;
        params['impact_approach'] = impactApproach;
        params['advocacy'] = advocacy;

        // Load assessment visuals
        $.ajax({
          type: "GET",
          dataType: "script",
          url: url + '.js?' + $.param(params)
        });

        // Remove ajax-loaded resources before Turbolinks cache; otherwise loads twice on back-button
        $(document).on("turbolinks:before-cache", function() {
          $('#' + vid).empty();
        })

      })        

    } 

    // Portfolio SDGs
    if ($('#sdgs_table').length > 0) {
      let sdgsTable = new SdgsTable($('#sdgs_table'));
      $.when( sdgsTable.render('sdgs_table') ).then( status => sdgsTable.setUp('sdgs_table') );
    }

    // Portfolio causes
    if ($('#portfolio_causes_table').length > 0) {
      let portfolioCausesTable = new PortfolioCausesTable($('#portfolio_causes_table'));
      $.when( portfolioCausesTable.render('portfolio_causes_table') ).then( status => portfolioCausesTable.setUp('portfolio_causes_table') );
    }

    // Listen for requests to load more data ('More' button at bottom of page)
    // On click, update the offset count
    if ( $('#loadMore').length !== 0 ) {

      // Load more
      $('.load-card-list').click( function() {

        // Update load offset to indicate where to start loading (offset) for more records
        let currentOffsetCount = parseInt( $(this).attr('data-api-offset-count') );
        $(this).attr('data-api-offset-count', currentOffsetCount + 6);

        // Set spinner
        $('.load-card-list h5').addClass('d-none');
        $('.load-card-list .text-container').append('<div class=\'small-spinner-container position-relative d-flex justify-content-center\'><div class=\'spinner-border text-primary\' role=\'status\'></div></div>');

        // Generate more impact profile cards
        CardList();

      });

      // If cards not already loaded, load
      if ( $(".list-card").length === 0 ) { 

        // Set a spinner to indicate table loading
        Spinner( $('.card-list-container-wide') );

        // Get and load data
        CardList();

      }
    }

    // Start import status poll if importing
    let importPoll = () => {

      // Get generating message on page
      let isGenerating = $('.generating-message');

      // Only continue if status indicator present (if should poll for recs is true)
      if (isGenerating.length !== 0) {

        // Set Api url to get data
        let teamUrl = isGenerating.attr('data-team-url');
        let portfolioId = isGenerating.attr('data-portfolio-id');
        let modelId = isGenerating.attr('data-model-id');
        let params = {};
        params['portfolio_id'] = portfolioId;
        params['scope'] = 'portfolio';
        let url = '/' + teamUrl + '/models/' + modelId + '/imports/check_import_status.js?' + $.param(params);

        // Execute ajax request (using js erb template to render content so can control profile styling more easily)
        // Must specify '.js' otherwise processes as JSON
        $.ajax({
          type: "POST",
          dataType: "script",
          timeout: 3000,
          url: url,
          complete() {
            setTimeout(function() { importPoll() }, 4000);
          }
        });
      }
    };

    // Poll server for status of mapped items, if generating message present
    if ($('.generating-message').length !== 0) { importPoll(); }

  }

}