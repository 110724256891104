import ClientSummaryUpdatesTable from 'src/javascripts/components/tables/ClientSummaryUpdatesTable';

export default class TeamsUpdateSummariesShow {

  constructor() {}

  render() {

    // Render top table
    if ( $('#client_summary_updates_table_wrapper').length === 0 ) {
      let table = new ClientSummaryUpdatesTable($('#client_summary_updates_table'));
      $.when( table.render() ).then( status => table.setUp('client_summary_updates_table') )
    }


  }

}