import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import {noResultsTemplate, emptyTemplate, genericDisplayTemplate, causeTemplate, logoTemplate, screenTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

// Set CORS variables
let sessionToken = $('body').attr('data-session-token');
let userEmail = $('body').attr('data-email');
let ethosCorsKey = $('body').attr('data-ethos-cors-key');
let mainEthosUrl = $('body').attr('data-main-ethos-url');

// Screens typeahead
var screens = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: 'https://' + mainEthosUrl + '/companies/screens/search.json?screen_version=strict&query=%QUERY',
    wildcard: "%QUERY"
  }
});
screens.initialize();
export const screensTypeahead = function(exclude = null) {
  $('#screens_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screens,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}

// Screens typeahead - optimizer
var screensOptimizer = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: 'https://' + mainEthosUrl + '/companies/screens/search.json?screen_version=strict&query=%QUERY&exclude=no_sbti',
    wildcard: "%QUERY"
  }
});
screensOptimizer.initialize();
export const screensTypeaheadOptimizer = function(exclude = null) {
  $('#screens_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screensOptimizer,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}

// Screens typeahead
var screensLite = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: 'https://' + mainEthosUrl + '/companies/screens/search.json?screen_version=lite&query=%QUERY',
    wildcard: "%QUERY"
  }
});
screens.initialize();
export const screensLiteTypeahead = function() {
  $('#screens_lite_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screensLite,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}

// Screens lite typeahead - optimizer
var screensLiteOptimizer = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: 'https://' + mainEthosUrl + '/companies/screens/search.json?screen_version=lite&query=%QUERY&exclude=no_sbti',
    wildcard: "%QUERY"
  }
});
screensLiteOptimizer.initialize();
export const screensLiteTypeaheadOptimizer = function() {
  $('#screens_lite_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screensLiteOptimizer,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}

var screenSets = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: 'https://' + mainEthosUrl + '/companies/screen_sets/search.json?query=%QUERY',
    wildcard: "%QUERY"
  }
});
screenSets.initialize();

// Screens typeahead
export const screenSetsTypeahead = function() {
  $('#screen_sets_typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 0
  }, {
    source: screenSets,
    name: "name",
    display: "name",
    limit: 332,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.name === null) {
          return noResultsTemplate();
        } else {
          return screenTemplate(el.name, el.fa_key);
        }
      }
    }
  });
}


// Company bloodhounds
export const companiesBloodhound = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: 'https://' + mainEthosUrl + '/research?query=%QUERY',
    wildcard: "%QUERY",
    transform: function(d) { return d.companies; }
  }
});

// Fund bloodhounds
export const fundsBloodhound = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: 'https://' + mainEthosUrl + '/research?query=%QUERY',
    wildcard: "%QUERY",
    transform: function(d) { return d.funds; }
  }
});

// Brand bloodhounds
export const brandsBloodhound = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: 'https://' + mainEthosUrl + '/companies/search?query=%QUERY',
    wildcard: "%QUERY",
    transform: function(d) { return d.brands; }
  }
});

// Initiate filter typeaheads
export const filterTypeahead = function(klass, name, url, limit, template) {
  let bloodhound = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: 'https://' + mainEthosUrl + url,
      prepare: function (query, settings) {
        settings.url = settings.url + 'query=' + query
        settings.headers = {
          "Content-Type": "application/json",
          "Ethos-Cors-Key": ethosCorsKey,
          "Session-Token": sessionToken,
          "Email": userEmail
        };
        return settings;
      }
    }});
  bloodhound.initialize();
  $(klass).typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
  }, {
    name: name,
    display: 'name',
    source: bloodhound,
    limit: limit,
    templates: {
      empty: emptyTemplate,
      suggestion(el) {
        if (el.value === null) {
          return noResultsTemplate();
        } else {
          if (template === 'generic') {
            if (typeof el.name !== 'undefined') {
              return genericDisplayTemplate(el.name);
            } else {
              return genericDisplayTemplate(el.value);
            }
          } else if (template === 'cause') {
            return causeTemplate(el.name, el.name_short, el.fa_key, el.value);
          } else if (template === 'logo') {
            return logoTemplate(el.name, el.symbol, el.logo_url, el.value);              
          }
        }
      },
    }
  });
} 

