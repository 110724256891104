import Table from 'src/javascripts/components/Table';
import Spinner from 'src/javascripts/components/utilities/Spinner';
import AddRowDataAttrs from 'src/javascripts/components/tables/helpers/AddRowDataAttrs';
import AddRowHoverButtons from 'src/javascripts/components/tables/helpers/AddRowHoverButtons';
import AddRowClickLinks from 'src/javascripts/components/tables/actions/AddRowClickLinks';
import GetCols from 'src/javascripts/components/tables/helpers/GetCols';
import CheckPaging from 'src/javascripts/components/tables/helpers/CheckPaging';

export default class ReturnsTable extends Table {

  render(tableId) {

    // Set a spinner to indicate chart loading
    Spinner(this.el);

    // Build url, use to get filtered data with server-side processing
    let dataUrl = $('#' + tableId).attr('data-url');

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Set additional data vars (used below in table creation)
    let cols = GetCols(this.el);

    // Set order from table attr or default
    let orderCol = $('#' + tableId).attr('data-order-col') || (cols.length - 1);

    // Render table
    // Responsive - details: false - don't show hidden details on small devices 
    // (https://datatables.net/reference/option/responsive)
    // Use 'retrieve' to avoid reinitialization error (https://datatables.net/manual/tech-notes/3)
    const table = $('#' + tableId).DataTable({
      retrieve: true,
      ajax: {
        url: dataUrl,
        type: 'GET',
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        }
      },
      paging: true,
      pageLength: 10,
      searching: true,
      ordering: true,
      order: [[orderCol, 'desc']],
      dom: 'tp',
      columns: cols,
      responsive: { details: false },
      language: { 
        infoEmpty: "No data available. Please contact us with any questions.",
        emptyTable: "No data available. Please contact us with any questions.",
        zeroRecords: "No data available. Please contact us with any questions."
      },
      createdRow(row, data, dataIndex) {

        // Add data attrs to row
        AddRowDataAttrs(row, data);

        // Add 'no click' if data has no_click attr
        if (typeof data.no_click !== 'undefined') {$(row).addClass('no-click');}

        // Add hover button links to rows, if passed in data
        if (typeof data.hover_links !== 'undefined') { AddRowHoverButtons(row, data.hover_links); }

      },

      drawCallback: function( settings ) {

        // Remove spinner after table has drawn
        $('#' + tableId).find('.spinner-container').remove();

        // Move hover container to end of row
        // Required because DataTables inserts the newly-visible columns after existing hover container
        // Causes problems when user changes dates
        $('.dt-hover-outer').each(function() { $(this).appendTo($(this).closest('tr')); });

      }

    });

    return 'rendered';
  }

  setUp(tableId, chartId) {

    // Set table
    let oTable = $('#' + tableId).DataTable();

    // Set searchable function for table
    $('#search_' + tableId).keyup(function(){
      oTable.search( $(this).val() ).draw();
    });

    // Ensure search input starts blank
    $('#search_' + tableId).val('');

    return 'setup';

  }

};
