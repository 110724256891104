import ToastCustom from 'src/javascripts/components/alerts/ToastCustom';

export default class NotificationsSettingsIndex {

  constructor() {}

  render() {

    // Set CORS variables
    let sessionToken = $('body').attr('data-session-token');
    let userEmail = $('body').attr('data-email');
    let ethosCorsKey = $('body').attr('data-ethos-cors-key');
    let mainEthosUrl = $('body').attr('data-main-ethos-url');

    // Update user's settings preferences on select
    let $settingInput = $('input.notification-setting-input');
    $settingInput.change(function() {
      
      // Set attrs
      let settingId = $(this).attr('data-setting-id');
      let setting = $(this).attr('data-setting');

      // Set params as true or false
      let params = {};
      params['notification_setting'] = {}

      if ( $(this)[0].checked ) {
        params['notification_setting'][setting] = 'true'
      } else {
        params['notification_setting'][setting] = 'false'
      }

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: 'https://' + mainEthosUrl + '/notifications/settings/' + settingId + '.js?' + $.param(params),
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        },
        complete(result) { 
          ToastCustom('Success', 'Updated your notification preference');
        }
      })
    });


    // Update user's settings preferences for min threshold
    let $thresholdSubmit = $('#updateSavedListThreshold');
    $thresholdSubmit.click(function() {
      
      // Set attrs
      let settingId = $(this).attr('data-setting-id');
      let setting = $(this).attr('data-setting');
      let threshold = $('#notifySavedListThreshold').val();

      // Set params as true or false
      let params = {};
      params['notification_setting'] = {}
      params['notification_setting'][setting] = threshold

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: '/notifications/settings/' + settingId + '.js?' + $.param(params),
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        },
        complete(result) { 
          ToastCustom('Success', 'Updated your minimum Impact Score preference');
        }
      })
    });

    // Update user's email frequency on select
    let $emailFreqSelect = $('#emailFrequencySelect');
    $emailFreqSelect.change(function() {
      
      // Set selection
      let settingId = $(this).attr('data-setting-id');
      let setting = $(this).attr('data-setting');
      let freq = $('#emailFrequencySelect').val();

      // Set params as true or false
      let params = {};
      params['notification_setting'] = {}
      params['notification_setting'][setting] = freq

      $.ajax({
        type: 'PATCH',
        dataType: "json",
        url: '/notifications/settings/' + settingId + '.js?' + $.param(params),
        beforeSend: function(request) {
          request.setRequestHeader("Content-Type", "application/json");
          request.setRequestHeader("Ethos-Cors-Key", ethosCorsKey);
          request.setRequestHeader("Session-Token", sessionToken);
          request.setRequestHeader("Email", userEmail);
        },
        complete(result) { 
          ToastCustom('Success', 'Updated your notifications email preference');
        }
      })
    });


  }

}